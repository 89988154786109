import styled from '@emotion/styled'
import React from 'react'

import { Maybe } from '@emico/graphql-schema-types'
import { maxWidth } from '@emico/styles'
import { H3 } from '@emico/ui'

import { CraftLinkGroupListFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import { StyledCraftLink } from './Footer'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: ${theme.spacing.md};

  @media ${maxWidth('md')} {
    display: none;
  }
`

const LinkGroupTitle = styled(H3)`
  margin-bottom: ${theme.spacing.xl};
  color: ${theme.colors.textLight};
  font-weight: ${theme.fontWeights.bold};
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin-bottom: ${theme.spacing.sm};

  &:last-child {
    margin-bottom: 0;
  }
`

interface Props {
  linkGroups: Array<Maybe<CraftLinkGroupListFragment>>
}

const FooterDesktop = ({ linkGroups }: Props) => (
  <Grid>
    {linkGroups?.map((linkGroup, index) => {
      if (!linkGroup || linkGroup?.links?.length === 0) {
        return null
      }

      return (
        <div key={index}>
          {linkGroup.groupTitle && (
            <LinkGroupTitle>{linkGroup.groupTitle}</LinkGroupTitle>
          )}

          <List>
            {linkGroup.links?.map((link, index) => {
              if (!link?.linkItem?.url || !link.linkItem.customText) {
                return null
              }

              return (
                <ListItem key={index}>
                  <StyledCraftLink
                    analyticsContext="footer"
                    analyticsName={link?.linkItem?.url}
                    craftLink={link.linkItem}
                  >
                    {link.linkItem.customText}
                  </StyledCraftLink>
                </ListItem>
              )
            })}
          </List>
        </div>
      )
    })}
  </Grid>
)

export default FooterDesktop
