import React, { SVGProps } from 'react'

const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 22"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M32.293 3.452a3.828 3.828 0 00-1.052-1.745 4.191 4.191 0 00-1.839-1.02c-2.58-.68-12.92-.68-12.92-.68-4.31-.05-8.62.166-12.903.646-.69.191-1.321.55-1.838 1.044A3.98 3.98 0 00.67 3.45 39.974 39.974 0 00.001 11c-.017 2.53.207 5.057.67 7.55a3.884 3.884 0 001.065 1.743c.51.485 1.145.836 1.843 1.021 2.615.68 12.903.68 12.903.68 4.316.048 8.63-.167 12.92-.647a4.19 4.19 0 001.839-1.02 3.821 3.821 0 001.05-1.745 39 39 0 00.69-7.55 36.525 36.525 0 00-.688-7.582v.002zM13.195 15.705V6.296l8.603 4.705-8.603 4.704z"
      fill="currentColor"
    />
  </svg>
)

export default YoutubeIcon
