import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import { ProductFragment } from '@emico-hooks/product-fragment'
import { stripMaybes } from '@emico-utils/graphql-data-utils'

import convertProductForTrackingEvent from './convertProductForTrackingEvent'

export default function getTrackingProducts(
  /**
   * List of products that should be converted
   */
  products: Array<ProductFragment | ProductCardFragment>,
  /**
   * Active storeview code
   */
  storeViewCode?: string,
  /**
   * Should the index/position of the product in a list be included?
   */
  hasIndex?: boolean,
) {
  return products
    .map(
      (product, index) =>
        product &&
        convertProductForTrackingEvent({
          product,
          quantity: 1,
          storeViewCode,
          index: hasIndex ? index : undefined,
        }),
    )
    .filter(stripMaybes)
}
