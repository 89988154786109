import { RadioGroup } from '@emico-react/input-radio'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { Control, FieldValues, UseFormRegister } from 'react-hook-form'

import { ProductConfigurableOptions } from '../lib/useProductConfigurables'
import theme from '../theme'
import { ColorSwatchOption } from './ColorSwatchOption'

const StyledRadioGroup = styled(RadioGroup)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(35px, 1fr));
  gap: ${theme.spacing.xs};
`

interface Props<T extends FieldValues> {
  option: ProductConfigurableOptions
  register: UseFormRegister<T>
  control: Control<T>
  resetFormFields: (attributeUid: string) => void
  defaultValues?: FieldValues
}

export const ColorSwatchOptions = <T extends FieldValues>({
  option,
  register,
  control,
  resetFormFields,
  defaultValues,
}: Props<T>) => (
  <StyledRadioGroup
    label={t({ message: 'Color' })}
    showLabel={false}
    defaultValue={defaultValues?.[option.key]}
    isRequired
  >
    {option.values?.map((value) => (
      <ColorSwatchOption
        key={value.key}
        optionKey={option.key}
        value={value}
        register={register}
        control={control}
        resetFormFields={resetFormFields}
      />
    ))}
  </StyledRadioGroup>
)
