import styled from '@emotion/styled'
import React from 'react'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { Modal as ModalBase } from '@emico/modal'
import ModalBackdrop from '@emico/modal-backdrop'
import { ModalSegueLeft } from '@emico/modal-segue'
import { minWidth } from '@emico/styles'

import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import MenuMobile from './MenuMobile'

const ModalBody = styled.section`
  background: ${theme.colors.background};
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;

  @media ${minWidth('md')} {
    width: 400px;
    overflow: hidden;
  }
`

interface Props extends DefaultLayoutStaticData {
  rootCategory: CategoryFragmentWithChildren
  show: boolean
  close: () => void
  setOpenStoreSelectorModal: (openStoreSelectorModal: boolean) => void
}

const MenuMobileModal = ({ show, close, ...other }: Props) => (
  <>
    <ModalBackdrop show={show} onClick={close} />

    <ModalSegueLeft show={show} renderBeforeShow>
      <ModalBase close={close} show={show} hasFocusTrap={false}>
        <ModalBody>
          <MenuMobile show={show} close={close} {...other} />
        </ModalBody>
      </ModalBase>
    </ModalSegueLeft>
  </>
)

export default MenuMobileModal
