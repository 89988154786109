import { TypedDocumentNode, gql, useQuery } from '@apollo/client'
import productCardFragment from '@emico-hooks/product-card-fragment'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import {
  ProductsQuery,
  ProductsQueryVariables,
} from './useProductsBySkus.generated'

const query = gql`
  query Products(
    $currentPage: Int!
    $pageSize: Int!
    $filter: ProductAttributeFilterInput!
  ) {
    products(pageSize: $pageSize, currentPage: $currentPage, filter: $filter) {
      items {
        ...ProductCardFragment
      }
    }
  }
  ${productCardFragment}
` as TypedDocumentNode<ProductsQuery, ProductsQueryVariables>

const useProductsBySkus = (skus: string[]) => {
  const { data, ...others } = useQuery(query, {
    variables: {
      filter: { sku: { in: skus } },
      currentPage: 1,
      pageSize: 12,
    },
    context: getCacheableContext(),
  })

  return {
    data: data?.products?.items ?? [],
    ...others,
  }
}

export default useProductsBySkus
