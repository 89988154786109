/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'

import { ImageSwatchData } from '@emico/graphql-schema-types/src'

import { ProductConfigurableOptionValue } from '../lib/useProductConfigurables'
import theme from '../theme'
import RadioCustom from './RadioCustom'

const Option = styled('div', {
  shouldForwardProp: (prop) =>
    !['isSelected', 'isDisabled'].includes(prop.toString()),
})<{ isSelected: boolean; isDisabled?: boolean }>`
  display: flex;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    border-bottom: 2px solid ${theme.colors.grayMiddle};
    width: 100%;
    transform: rotate(-45deg) translate(0%, 50%);
    top: 50%;
    display: ${({ isDisabled }) => !isDisabled && 'none'};
  }
  box-sizing: border-box;
  background-color: ${theme.colors.grayLight};
  border: ${theme.borders.base};
  border-color: ${({ isSelected }) =>
    isSelected ? theme.colors.grayDark : 'transparent'};
  border-width: 0 0 2px 0;
  min-height: 52px;
`

const StyledRadioCustom = styled(RadioCustom as any)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.xs};
  cursor: pointer;
`

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => !['isDisabled'].includes(prop.toString()),
})<{ isDisabled?: boolean }>`
  opacity: ${({ isDisabled }) => isDisabled && 0.4};
  width: 100%;
  object-fit: contain;
`

interface Props<T extends FieldValues> {
  optionKey: string
  value: ProductConfigurableOptionValue
  register: UseFormRegister<T>
  resetFormFields: (attributeUid: string) => void
}

const ImageSwatchOption = <T extends FieldValues>({
  optionKey,
  value,
  register,
  resetFormFields,
}: Props<T>) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)

  const { label, disabled } = value

  const handleStateChange = (isSelected: boolean) => {
    setIsSelected(isSelected)
  }

  return (
    <Option isSelected={isSelected} isDisabled={disabled}>
      <StyledRadioCustom
        value={value.key}
        showButton={false}
        hasCustomSelectState={false}
        handleCustomRadioState={handleStateChange}
        isDisabled={disabled}
        onFocusChange={() => resetFormFields(optionKey)}
        {...register(optionKey as Path<T>, {
          required: true,
        })}
      >
        {(value?.swatch as ImageSwatchData)?.thumbnail ? (
          <StyledImage
            url={(value.swatch as ImageSwatchData).thumbnail || ''}
            alt={label}
            width={70}
            height={40}
            lazy={false}
            isDisabled={disabled}
          />
        ) : null}
      </StyledRadioCustom>
    </Option>
  )
}

export default ImageSwatchOption
