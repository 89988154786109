/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import {
  Control,
  FieldValues,
  Path,
  UseFormRegister,
  useController,
} from 'react-hook-form'

import CircleFilledIcon from '../icons/CircleFilledIcon'
import { ProductConfigurableOptionValue } from '../lib/useProductConfigurables'
import theme from '../theme'
import RadioCustom from './RadioCustom'

const StyledRadioCustom = styled(RadioCustom as any, {
  shouldForwardProp: (prop) =>
    !['isSelected', 'hasError'].includes(prop.toString()),
})<{ isSelected: boolean; hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: ${theme.spacing.md};
  cursor: pointer;
  border: ${theme.borders.base};
  border-color: ${({ isSelected, hasError }) =>
    hasError
      ? theme.colors.error
      : isSelected
      ? theme.colors.grayBrown
      : 'transparent'};
  border-radius: 50%;
  aspect-ratio: 1 / 1;
`

const StyledCircleFilledIcon = styled(CircleFilledIcon, {
  shouldForwardProp: (props) => !['color'].includes(props.toString()),
})<{ color?: string }>`
  fill: ${({ color }) => color || theme.colors.white};
  font-size: 23px;
`

interface Props<T extends FieldValues> {
  optionKey: string
  value: ProductConfigurableOptionValue
  register: UseFormRegister<T>
  control: Control<T>
  resetFormFields: (attributeUid: string) => void
}

export const ColorSwatchOption = <T extends FieldValues>({
  optionKey,
  value,
  register,
  control,
  resetFormFields,
}: Props<T>) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)

  const { key, disabled = false, attributeUid, swatch } = value
  const handleStateChange = (isSelected: boolean) => {
    setIsSelected(isSelected)
  }

  const { fieldState } = useController({
    name: optionKey as Path<T>,
    control: control,
  })
  const hasError = Boolean(fieldState.error)

  useEffect(() => {
    /**
     * If `color` attribute's value has a `disabled` state, it means this color is not available with the current combination of configurable options.
     * Because one should always be able to pick a different color, other configurable options we be reset when a disabled color is selected.
     */
    if (disabled) {
      resetFormFields(attributeUid)
    }
  }, [isSelected])

  return (
    <StyledRadioCustom
      key={key}
      isSelected={isSelected}
      hasError={hasError}
      value={key}
      showButton={false}
      hasCustomSelectState={false}
      handleStateChange={handleStateChange}
      {...register(optionKey as Path<T>, {
        required: true,
      })}
    >
      {swatch?.value ? <StyledCircleFilledIcon color={swatch.value} /> : null}
    </StyledRadioCustom>
  )
}
