import { SVGProps } from 'react'
export const PinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.51.56a8.6 8.6 0 0 0-8.6 8.6c0 7.35 7.82 12.9 8.15 13.14a.81.81 0 0 0 .9 0c.33-.23 8.14-5.79 8.14-13.14A8.6 8.6 0 0 0 9.51.56Zm0 5.47a3.12 3.12 0 1 1 0 6.25 3.12 3.12 0 0 1 0-6.25Z"
    />
  </svg>
)
