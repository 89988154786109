import React, { SVGProps } from 'react'

const CircleFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <circle cx={13} cy={13} r={13} fill="current" />
  </svg>
)

export default CircleFilledIcon
