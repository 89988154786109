import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import { ProductFragment } from '@emico-hooks/product-fragment'

interface Props {
  product: ProductFragment | ProductCardFragment
  quantity: number | undefined | null
  categoryName?: string
  storeViewCode?: string
  index?: number
}

/**
 * Function to convert product data to datalayer object
 */

export default function convertProductForTrackingEvent({
  product,
  quantity,
  categoryName,
  storeViewCode,
  index,
}: Props) {
  return {
    item_id: product.sku,
    item_name: product.name,
    price: product.priceRange.minimumPrice.finalPrice.value,
    discount: product.priceRange.minimumPrice.discount?.amountOff ?? 0,
    quantity: quantity ?? 1,
    item_brand: 'BERG',
    item_category: categoryName ?? product.categories?.[0]?.name,
    affiliation: storeViewCode,
    index,
  }
}
