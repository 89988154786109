import React, { SVGProps } from 'react'

const HeartIconFill = (props: SVGProps<SVGSVGElement>) => (
  <svg height="1em" width="1em" viewBox="0 0 21 18.8" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M15.3,1.5c0.5,0,1.1,0.1,1.6,0.3s1,0.5,1.3,0.9c0.8,0.8,1.2,1.9,1.2,3c0,1.1-0.4,2.2-1.2,3l-7.8,7.9L2.7,8.7  c-0.8-0.8-1.2-1.9-1.2-3c0-1.1,0.4-2.2,1.2-3C3.1,2.3,3.5,2,4,1.8s1-0.3,1.6-0.3s1.1,0.1,1.6,0.3c0.5,0.2,1,0.5,1.3,0.9l1.9,1.9  l1.9-1.9c0.4-0.4,0.8-0.7,1.3-0.9C14.2,1.6,14.8,1.5,15.3,1.5z M15.3,0c-0.7,0-1.5,0.1-2.2,0.4c-0.7,0.3-1.3,0.7-1.8,1.2l-0.8,0.8  L9.6,1.6c-0.5-0.5-1.1-1-1.8-1.2S6.4,0,5.6,0S4.1,0.1,3.5,0.4C2.8,0.7,2.1,1.1,1.6,1.6C0.6,2.7,0,4.2,0,5.7s0.6,3,1.7,4.1l8.8,9  l8.8-9C20.4,8.7,21,7.2,21,5.7s-0.6-3-1.7-4.1c-0.5-0.5-1.1-1-1.8-1.2C16.8,0.1,16,0,15.3,0z"
    />
    <path
      fill="currentColor"
      d="M15.3,0c-0.7,0-1.5,0.1-2.2,0.4c-0.7,0.3-1.3,0.7-1.8,1.2l-0.8,0.8L9.6,1.6c-0.5-0.5-1.1-1-1.8-1.2  C7.1,0.1,6.4,0,5.6,0S4.1,0.1,3.5,0.4C2.8,0.7,2.1,1.1,1.6,1.6C0.6,2.7,0,4.2,0,5.7s0.6,3,1.7,4.1l8.8,9l8.8-9  C20.4,8.7,21,7.2,21,5.7s-0.6-3-1.7-4.1c-0.5-0.5-1.1-1-1.8-1.2C16.8,0.1,16,0,15.3,0z"
    />
  </svg>
)

export default HeartIconFill
