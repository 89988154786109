import styled from '@emotion/styled'
import React from 'react'

import StarIcon from '../icons/StarIcon'
import theme from '../theme'

const Stars = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
`

const StyledStarIcon = styled(StarIcon, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop.toString()),
})<{ isActive?: boolean }>`
  color: ${({ isActive }) =>
    isActive ? theme.colors.yellow : theme.colors.grayMiddle};
`

interface Props {
  /**
   * Number of stars rated
   */
  value: number
  /**
   * Total number of stars
   */
  totalStars?: number
}

const StarRating = ({ value, totalStars = 5, ...other }: Props) => {
  const starsList = [...Array(totalStars).keys()]

  return (
    <Stars {...other}>
      {starsList.map((starIndex) => {
        const starValue = starIndex + 1

        return (
          <StyledStarIcon
            key={starIndex}
            isActive={starValue <= Math.round(value)}
          />
        )
      })}
    </Stars>
  )
}

export default StarRating
