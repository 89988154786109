import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import React, { HTMLAttributes, ReactNode, useEffect, useState } from 'react'
import { Collapse } from 'react-collapse'

import { InfoCircleIcon } from '@emico/icons'
import { H2, H3 } from '@emico/ui'

import ChevronDownIcon from '../icons/ChevronDownIcon'
import theme from '../theme'

const AccordionWrapper = styled.div`
  overflow: hidden;

  // Necessary to animate collapse change (https://www.npmjs.com/package/react-collapse)
  .ReactCollapse--collapse {
    transition: height 400ms;
  }
`

export const AccordionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LabelWrapper = styled('div', {
  shouldForwardProp: (prop) => !['hasInfoIcon'].includes(String(prop)),
})<{
  hasInfoIcon?: boolean
}>`
  display: flex;
  align-items: flex-end;
  width: ${({ hasInfoIcon }) => !hasInfoIcon && '100%'};
`

const LabelHeadingH2 = styled(H2, {
  shouldForwardProp: (prop) =>
    !['hasChevronIcon', 'hasInfoIcon'].includes(String(prop)),
})<{
  hasChevronIcon?: boolean
  hasInfoIcon?: boolean
}>`
  width: ${({ hasChevronIcon, hasInfoIcon }) =>
    hasChevronIcon && !hasInfoIcon && '100%'};
  cursor: pointer;
`

const LabelHeadingH3 = styled(H3, {
  shouldForwardProp: (prop) =>
    !['hasChevronIcon', 'hasInfoIcon'].includes(String(prop)),
})<{
  hasChevronIcon?: boolean
  hasInfoIcon?: boolean
}>`
  width: ${({ hasChevronIcon, hasInfoIcon }) =>
    hasChevronIcon && !hasInfoIcon && '100%'};
  cursor: pointer;
`

const LabelButton = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) =>
    !['hasChevronIcon', 'hasInfoIcon'].includes(String(prop)),
})<{
  hasChevronIcon?: boolean
  hasInfoIcon?: boolean
}>`
  width: ${({ hasChevronIcon, hasInfoIcon }) =>
    hasChevronIcon && !hasInfoIcon && '100%'};
`

const InfoButton = styled(ButtonUnstyled)`
  margin: 0 ${theme.spacing.xs} 3px;
  font-size: 0;
`

const StyledInfoCircleIcon = styled(InfoCircleIcon)`
  font-size: ${theme.fontSizes.md};
`

const ChevronButton = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => !['hasInfoIcon'].includes(String(prop)),
})<{
  hasInfoIcon?: boolean
}>`
  width: ${({ hasInfoIcon }) => hasInfoIcon && '100%'};
  text-align: right;
`

const StyledChevronDownIcon = styled(ChevronDownIcon, {
  shouldForwardProp: (prop) => prop !== 'isToggled',
})<{ isToggled?: boolean }>`
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.slow};
  transition-timing-function: ${theme.transition.timingFunctions.easeInOut};
  transform: ${({ isToggled }) => (isToggled ? 'rotate(-180deg)' : 0)};
`

const ToggleButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isToggled',
})<{ isToggled?: boolean }>`
  width: 16px;
  height: 16px;
  position: relative;
  flex-shrink: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    transition-property: ${theme.transition.properties.common};
    transition-duration: ${theme.transition.durations.slow};
    transition-timing-function: ${theme.transition.timingFunctions.easeInOut};
    background-color: currentColor;
  }

  &::before {
    top: 0;
    bottom: 0;
    width: 2px;
    left: 50%;
    transform: ${({ isToggled }) =>
      isToggled ? 'rotate(-90deg) translateX(-50%)' : 'translateX(-50%)'};
  }

  &::after {
    left: 0;
    right: 0;
    height: 2px;
    top: 50%;
    transform: ${({ isToggled }) =>
      isToggled ? 'rotate(180deg)' : 'translateY(-50%)'};
  }
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  /**
   * Accordion label prop is wrapped by a button, so only phrasing inline content allowed (e.g. label, span)
   */
  label: ReactNode
  /**
   * Should accordion have a chevron feedback icon?
   */
  hasChevronIcon?: boolean
  /**
   * Should accordion children be visible by default?
   */
  isCollapsedDefault?: boolean
  /**
   * Should accordion icon be a plus icon?
   */
  hasPlusIcon?: boolean
  /**
   * On click function, pass if accordion info icon should be shown
   */
  onInfoClick?: () => void
  /**
   * Which element should be used as label?
   * `span` is default and preferred, because this allows to use a button as interactive element.
   * When other flow content elements, such as h2, are used as label, this is not permitted as button child element.
   */
  labelElement?: 'span' | 'h2' | 'h3'
}

const Accordion = ({
  label,
  children,
  hasChevronIcon = true,
  isCollapsedDefault = false,
  hasPlusIcon = true,
  onInfoClick,
  labelElement = 'span',
  ...other
}: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(
    isCollapsedDefault ?? false,
  )

  const icon = isVisible ? <ToggleButton isToggled /> : <ToggleButton />
  const iconChevron = <StyledChevronDownIcon isToggled={isVisible} />
  const customIcon = hasPlusIcon ? icon : iconChevron

  const hasInfo = Boolean(onInfoClick)

  const toggleAccordion = () => {
    setIsVisible((isVisible) => !isVisible)
  }

  function getLabelButton() {
    switch (labelElement) {
      case 'h2':
        return (
          <LabelHeadingH2
            onClick={toggleAccordion}
            hasChevronIcon={hasChevronIcon}
            hasInfoIcon={hasInfo}
          >
            {label}
          </LabelHeadingH2>
        )
      case 'h3':
        return (
          <LabelHeadingH3
            onClick={toggleAccordion}
            hasChevronIcon={hasChevronIcon}
            hasInfoIcon={hasInfo}
          >
            {label}
          </LabelHeadingH3>
        )
      default:
        return (
          <LabelButton
            analyticsContext="accordion"
            analyticsName="toggle"
            onPress={toggleAccordion}
            hasChevronIcon={hasChevronIcon}
            hasInfoIcon={hasInfo}
          >
            {label}
          </LabelButton>
        )
    }
  }

  useEffect(() => {
    setIsVisible(isCollapsedDefault)
  }, [isCollapsedDefault, setIsVisible])

  return (
    <AccordionWrapper {...other}>
      <AccordionHeader>
        <LabelWrapper hasInfoIcon={hasInfo}>
          {getLabelButton()}

          {onInfoClick && (
            <InfoButton
              analyticsContext="accordion"
              analyticsName="info"
              onPress={onInfoClick}
            >
              <StyledInfoCircleIcon />
            </InfoButton>
          )}
        </LabelWrapper>

        {hasChevronIcon && (
          <ChevronButton
            analyticsContext="accordion"
            analyticsName="chevron-toggle"
            onPress={toggleAccordion}
            hasInfoIcon={hasInfo}
          >
            {customIcon}
          </ChevronButton>
        )}
      </AccordionHeader>

      <Collapse isOpened={isVisible}>{children}</Collapse>
    </AccordionWrapper>
  )
}

export default Accordion
