import React from 'react'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { ModalSegueLeft } from '@emico/modal-segue'

import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import FocusScope from './FocusScope'
import SearchMobileModalContent from './SearchMobileModalContent'

interface Props extends DefaultLayoutStaticData {
  show: boolean
  close: () => void
  rootCategory: CategoryFragmentWithChildren
}

const SearchMobileModal = ({
  show,
  close,
  websiteData,
  rootCategory,
}: Props) => (
  <ModalSegueLeft show={show}>
    <FocusScope>
      <SearchMobileModalContent
        close={close}
        websiteData={websiteData}
        rootCategory={rootCategory}
      />
    </FocusScope>
  </ModalSegueLeft>
)

export default SearchMobileModal
