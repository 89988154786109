import React, { SVGProps } from 'react'

const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M15.483 5.38l-4.797-.697L8.542.336a.607.607 0 00-1.084 0L5.314 4.683.517 5.38a.603.603 0 00-.334 1.032l3.47 3.383-.82 4.778a.604.604 0 00.877.636L8 12.954l4.29 2.255a.604.604 0 00.877-.637l-.82-4.777 3.47-3.383a.602.602 0 00-.334-1.031z"
      fill="currentColor"
    />
  </svg>
)

export default StarIcon
