import React, { useEffect, useRef } from 'react'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { Maybe } from '@emico/graphql-schema-types'

import {
  CraftLinkFragment,
  CraftMenuItemFragment,
} from '../lib/craftFragments.generated'
import {
  LinkLabel,
  ListItem,
  MainLinkCraftLink,
  StyledNextLink,
} from './MenuDesktopMainLinks'
import MenuDesktopSubmenu from './MenuDesktopSubmenu'

interface Props {
  category: CategoryFragmentWithChildren
  activeCategory: CategoryFragmentWithChildren | null
  handleItemWithChildren: (category: CategoryFragmentWithChildren) => void
  submenuItem: Maybe<CraftMenuItemFragment>
  deviatingUrl?: CraftLinkFragment
  show: boolean
  isSubActive: boolean
  setActiveSubmenuHeight: (activeSubmenuHeight?: number) => void
}

const MenuDesktopItemWithChildren = ({
  category,
  activeCategory,
  handleItemWithChildren,
  submenuItem,
  deviatingUrl,
  show,
  isSubActive,
  setActiveSubmenuHeight,
}: Props) => {
  const nodeRef = useRef(null)
  const submenuItemRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setActiveSubmenuHeight(submenuItemRef.current?.clientHeight)
  }, [setActiveSubmenuHeight])

  return (
    <ListItem onMouseEnter={() => handleItemWithChildren(category)}>
      {deviatingUrl?.url ? (
        <MainLinkCraftLink
          craftLink={deviatingUrl}
          analyticsContext="header.desktop.mainLinks"
          analyticsName={category.name}
          isActive={activeCategory === category}
        >
          <LinkLabel>{category.name}</LinkLabel>
        </MainLinkCraftLink>
      ) : (
        <StyledNextLink
          analyticsContext="header.desktop.mainLinks"
          analyticsName={category.name}
          isActive={activeCategory === category}
          href={`/${
            category.canonicalUrl ?? category.urlPath ?? category.urlKey
          }`}
        >
          <LinkLabel>{category.name}</LinkLabel>
        </StyledNextLink>
      )}

      {Boolean(submenuItem) && (
        <Transition nodeRef={nodeRef} in={show} timeout={800}>
          {(transitionStatus: TransitionStatus) => (
            <MenuDesktopSubmenu
              submenuItem={submenuItem}
              isSubActive={isSubActive}
              submenuItemRef={submenuItemRef}
              transitionStatus={transitionStatus}
            />
          )}
        </Transition>
      )}
    </ListItem>
  )
}

export default MenuDesktopItemWithChildren
