import React from 'react'
import { Transition } from 'react-transition-group'

import { Maybe } from '@emico/graphql-schema-types'
import { theme } from '@emico/styles'

import { CraftMenuItemFragment } from '../lib/craftFragments.generated'
import MenuMobileSubmenu from './MenuMobileSubmenu'

interface Props {
  submenuItem: Maybe<CraftMenuItemFragment>
  close: () => void
  back: () => void
  isMenuOpen: boolean
  isSubOpen: boolean
  isSubActive: boolean
}

const TransitionMenu = ({
  submenuItem,
  close,
  isMenuOpen,
  isSubOpen,
  isSubActive,
  back,
}: Props) => (
  <Transition
    in={isSubOpen && isSubActive}
    timeout={theme.transitionSpeed.slow}
  >
    {(state) => (
      <MenuMobileSubmenu
        submenuItem={submenuItem}
        close={close}
        isMenuOpen={isMenuOpen}
        isSubOpen={isSubOpen}
        isSubActive={isSubActive}
        back={back}
        transitionStatus={state}
      />
    )}
  </Transition>
)

export default TransitionMenu
