import styled from '@emotion/styled'
import React, { MutableRefObject, useEffect } from 'react'
import { TransitionStatus } from 'react-transition-group/Transition'

import { Maybe } from '@emico/graphql-schema-types'

import { CraftMenuItemFragment } from '../lib/craftFragments.generated'
import { opacityStates } from '../lib/transitionStates'
import theme from '../theme'
import Container from './Container'
import CraftGenericBlockLink from './CraftGenericBlockLink'
import CraftLink from './CraftLink'

const MenuItemContent = styled('div', {
  shouldForwardProp: (prop) => !['transitionStatus'].includes(prop.toString()),
})<{
  transitionStatus: TransitionStatus
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: ${theme.spacing.xl} 0;
  opacity: ${({ transitionStatus }) => opacityStates[transitionStatus]};
  background-color: ${theme.colors.background};
`

const MenuItem = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${theme.spacing['2xl']};
`

const Column = styled.div`
  display: flex;
  gap: ${theme.spacing.lg};
  flex-direction: column;
`

const StyledCraftGenericBlockLink = styled(CraftGenericBlockLink)`
  grid-column-start: 4;
`

const List = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`

const StyledCraftLink = styled(CraftLink)`
  color: ${theme.colors.grayDark};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.grayDark};
    text-decoration: none;
  }
`

const UngroupedLinkList = styled(List)`
  flex-direction: column;
  align-items: flex-start;
`

const GroupedLinkList = styled(List)`
  margin-bottom: ${theme.spacing.lg};
  flex-direction: column;
  align-items: flex-start;

  &:last-child {
    margin-bottom: 0;
  }
`

const ListItem = styled.li`
  margin-bottom: ${theme.spacing.sm};

  &:last-child {
    margin-bottom: 0;
  }
`

const ListItemUngrouped = styled.li`
  margin-bottom: ${theme.spacing.lg};

  &:last-child {
    margin-bottom: 0;
  }
`

const LinkGroupTitleLink = styled(StyledCraftLink)`
  font-weight: ${theme.fontWeights.bold};
  ${theme.animation.linkHover()};
`

const UngroupedCraftLink = styled(StyledCraftLink)`
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: ${theme.spacing.lg};
  ${theme.animation.linkHover()};

  &:last-child {
    margin-bottom: 0;
  }
`

const GroupedCraftLink = styled(StyledCraftLink)`
  ${theme.animation.linkHover()};
`

interface Props {
  submenuItem: Maybe<CraftMenuItemFragment>
  submenuItemRef: MutableRefObject<HTMLDivElement | null>
  transitionStatus: TransitionStatus
  setNestedTransitionStatus?: (
    nestedSubMenuTransitionStatus: TransitionStatus,
  ) => void
}

const MenuDesktopSubmenuItemContent = ({
  submenuItem,
  submenuItemRef,
  transitionStatus,
  setNestedTransitionStatus,
}: Props) => {
  const genericBlockLink = submenuItem?.genericBlockLink?.[0]

  // The transition status of this submenu item content is passed to parent MenuDesktopSubmenuItem
  // with the setNestedTransitionStatus prop and is updated based on the child's transitionStatus.
  useEffect(() => {
    if (setNestedTransitionStatus) {
      setNestedTransitionStatus(transitionStatus)
    }
  }, [setNestedTransitionStatus, transitionStatus])

  return (
    <MenuItemContent ref={submenuItemRef} transitionStatus={transitionStatus}>
      <Container>
        <MenuItem>
          {submenuItem?.menuLists?.map((menuList, index) => {
            const linkGroups = menuList?.linkGroups

            return (
              <Column key={index}>
                {menuList?.links?.length !== 0 && (
                  <UngroupedLinkList>
                    {menuList?.links?.map((link, index) =>
                      link?.linkItem?.url && link.linkItem.customText ? (
                        <ListItemUngrouped key={index}>
                          <UngroupedCraftLink
                            analyticsContext="header.desktop.mainLinks"
                            analyticsName={link.linkItem.url}
                            craftLink={link.linkItem}
                          >
                            {link.linkItem.customText}
                          </UngroupedCraftLink>
                        </ListItemUngrouped>
                      ) : null,
                    )}
                  </UngroupedLinkList>
                )}

                {linkGroups?.length !== 0 &&
                  linkGroups?.map((linkGroup, index) => {
                    const linkGroupTitleLink = linkGroup?.linkGroupTitleLink
                    const linkGroupLinks = linkGroup?.linkGroupLinks

                    return (
                      <div
                        key={`${linkGroup?.linkGroupTitleLink?.customText}-${index}`}
                      >
                        {(linkGroupLinks?.length !== 0 ||
                          (linkGroupTitleLink?.url &&
                            linkGroupTitleLink.customText)) && (
                          <GroupedLinkList>
                            {linkGroupTitleLink?.url &&
                              linkGroupTitleLink.customText && (
                                <ListItem>
                                  <LinkGroupTitleLink
                                    craftLink={linkGroupTitleLink}
                                    prefetch={false}
                                  >
                                    {linkGroupTitleLink.customText}
                                  </LinkGroupTitleLink>
                                </ListItem>
                              )}

                            {linkGroupLinks?.map((groupLink, index) =>
                              groupLink?.linkItem?.url &&
                              groupLink.linkItem.customText ? (
                                <ListItem key={index}>
                                  <GroupedCraftLink
                                    craftLink={groupLink.linkItem}
                                    prefetch={false}
                                  >
                                    {groupLink.linkItem.customText}
                                  </GroupedCraftLink>
                                </ListItem>
                              ) : null,
                            )}
                          </GroupedLinkList>
                        )}
                      </div>
                    )
                  })}
              </Column>
            )
          })}

          {genericBlockLink &&
            genericBlockLink.__typename === 'CraftGenericBlockLinkEntry' && (
              <StyledCraftGenericBlockLink item={genericBlockLink} />
            )}
        </MenuItem>
      </Container>
    </MenuItemContent>
  )
}

export default MenuDesktopSubmenuItemContent
