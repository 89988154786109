import React, { SVGProps } from 'react'

const AccountIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M10 9.84c2.72 0 4.96-2.16 4.96-4.88 0-2.72-2.24-4.88-4.96-4.88a4.8 4.8 0 00-4.96 4.8c0 2.72 2.24 4.96 4.96 4.96zm0-8.24a3.38 3.38 0 013.36 3.36A3.38 3.38 0 0110 8.32a3.38 3.38 0 01-3.36-3.36C6.64 3.04 8.16 1.6 10 1.6zM10 20h9.04c.32 0 .56-.4.56-.72-.16-1.92-.4-2.48-.56-3.28-.48-1.6-1.68-3.6-3.76-4.56-1.28-.56-2.72-.56-4.24-.56H9.12c-1.52 0-2.96-.08-4.24.56C2.8 12.4 1.52 14.4 1.12 16c-.24.8-.4 1.36-.56 3.28 0 .32.24.72.56.72h18.16M2.16 18.32c.08-.56.24-1.28.4-1.92.32-1.2 1.28-2.72 2.8-3.52.72-.4 2.32-.4 4.56-.4 2.32 0 3.84 0 4.56.4 1.52.8 2.48 2.32 2.8 3.52.16.72.32 1.36.4 1.92"
      fill="currentColor"
    />
  </svg>
)

export default AccountIcon
