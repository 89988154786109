import React, { SVGProps } from 'react'

const BarsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M21.333 19.333H2.667a.667.667 0 110-1.333h18.666a.666.666 0 110 1.333zM21.333 12.667H2.667a.667.667 0 010-1.334h18.666a.667.667 0 010 1.334zM21.333 6H2.667a.667.667 0 010-1.333h18.666a.667.667 0 110 1.333z"
      fill="currentColor"
    />
  </svg>
)

export default BarsIcon
