import { RadioGroup } from '@emico-react/input-radio'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { StaticImageData } from 'next/image'
import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import { minWidth } from '@emico/styles'

import { ProductConfigurableOptions } from '../lib/useProductConfigurables'
import theme from '../theme'
import ImageSwatchOption from './ImageSwatchOption'

const StyledRadioGroup = styled(RadioGroup)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${theme.spacing.sm};

  margin-top: ${theme.spacing.sm};

  @media ${minWidth('md')} {
    grid-template-columns: repeat(6, 1fr);
  }

  @media ${minWidth('lg')} {
    grid-template-columns: repeat(4, 1fr);
  }
`

export interface PlateType {
  name: string
  code: string
  image: StaticImageData
}

interface Props<T extends FieldValues> {
  option: ProductConfigurableOptions
  register: UseFormRegister<T>
  resetFormFields: (attributeUid: string) => void
  defaultValues?: FieldValues
}

const ImageSwatchOptions = <T extends FieldValues>({
  option,
  register,
  resetFormFields,
  defaultValues,
}: Props<T>) => (
  <StyledRadioGroup
    label={t({ message: 'Model' })}
    showLabel={false}
    defaultValue={defaultValues?.[option.key]}
  >
    {option.values?.map((value) => (
      <ImageSwatchOption
        key={value.key}
        optionKey={option.key}
        value={value}
        register={register}
        resetFormFields={resetFormFields}
      />
    ))}
  </StyledRadioGroup>
)

export default ImageSwatchOptions
