import styled from '@emotion/styled'
import React, { ComponentProps, ReactNode } from 'react'

import NextLink from './NextLink'

const StyledNextLink = styled(NextLink)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

interface Props extends ComponentProps<typeof NextLink> {
  children: ReactNode
  productName: string
}

const ProductCardLink = ({
  children,
  analyticsContext,
  analyticsName,
  productName,
  ...other
}: Props) => (
  <StyledNextLink
    analyticsContext={analyticsContext}
    analyticsName={analyticsName ?? productName}
    {...other}
  >
    {children}
  </StyledNextLink>
)

export default ProductCardLink
