import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { ButtonUnstyled, H1 } from '@emico/ui'

import CrossIcon from '../icons/CrossIcon'
import theme from '../theme'

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.lg};
  height: ${theme.sizes.fullScreenModalHeaderHeight};
`

const Title = styled(H1)`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.medium};
`

const StyledCrossIcon = styled(CrossIcon)`
  font-size: 14px;
`

interface Props {
  close: () => void
}

const SearchMobileModalHeader = ({ close }: Props) => (
  <Header>
    <Title>
      <Trans>Search</Trans>
    </Title>

    <ButtonUnstyled
      analyticsContext="search.mobile.modal"
      analyticsName="close"
      onClick={close}
    >
      <StyledCrossIcon />
    </ButtonUnstyled>
  </Header>
)

export default SearchMobileModalHeader
