import styled from '@emotion/styled'
import React from 'react'

import { Maybe } from '@emico/graphql-schema-types'
import { minWidth } from '@emico/styles'

import { CraftLinkGroupListFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import Accordion, { AccordionHeader } from './Accordion'
import { StyledCraftLink } from './Footer'

const Wrapper = styled.div`
  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledAccordion = styled(Accordion)`
  ${AccordionHeader} {
    color: ${theme.colors.textLight};
    margin-bottom: ${theme.spacing.md};
    padding-bottom: ${theme.spacing.md};
    border-bottom: ${theme.borders.default};
  }
`

const LinkGroupTitle = styled.span`
  color: ${theme.colors.textLight};
`

const List = styled.ul`
  margin: 0;
  padding: 0 0 ${theme.spacing.xl};
  list-style: none;
`

const ListItem = styled.li`
  margin-bottom: ${theme.spacing.sm};

  &:last-child {
    margin-bottom: 0;
  }
`

interface Props {
  linkGroups: Array<Maybe<CraftLinkGroupListFragment>>
}

const FooterMobile = ({ linkGroups }: Props) => (
  <Wrapper>
    {linkGroups?.map((linkGroup, index) => {
      if (!linkGroup?.groupTitle || linkGroup?.links?.length === 0) {
        return null
      }

      return (
        <StyledAccordion
          key={index}
          label={<LinkGroupTitle>{linkGroup.groupTitle}</LinkGroupTitle>}
        >
          <List>
            {linkGroup.links?.map((link, index) => {
              if (!link?.linkItem?.url || !link.linkItem.customText) {
                return null
              }

              return (
                <ListItem key={index}>
                  <StyledCraftLink
                    analyticsContext="footer"
                    analyticsName={link?.linkItem?.url}
                    craftLink={link.linkItem}
                  >
                    {link.linkItem.customText}
                  </StyledCraftLink>
                </ListItem>
              )
            })}
          </List>
        </StyledAccordion>
      )
    })}
  </Wrapper>
)

export default FooterMobile
