import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import {
  ProductLabelFragment,
  ProductLabelListFragment,
} from '@emico/product-label-fragment'
import { groupArrayByProperty } from '@emico/utils'

import theme from '../theme'

export enum GridLayoutTypeEnum {
  ROW = 'row',
  COLUMN = 'column',
}

interface GroupedProductLabels {
  groupName: string
  groupItems: ProductLabelFragment[]
}

function getLabelPosition(position: string) {
  switch (position) {
    case 'top-right':
      return css`
        top: 0;
        right: 0;
      `
    case 'bottom-left':
      return css`
        bottom: 0;
        left: 0;
      `
    case 'bottom-right':
      return css`
        bottom: 0;
        right: 0;
      `
    default:
      return css`
        top: 0;
        left: 0;
      `
  }
}

const LabelGroup = styled('div', {
  shouldForwardProp: (prop) =>
    !['position', 'gridLayoutType'].includes(prop.toString()),
})<{ position: string; gridLayoutType: GridLayoutTypeEnum }>`
  display: flex;
  flex-direction: ${({ gridLayoutType }) =>
    gridLayoutType === GridLayoutTypeEnum.COLUMN ? 'column' : 'row'};
  flex-wrap: wrap;
  gap: ${theme.spacing.xs};
  position: absolute;
  ${({ position }) => getLabelPosition(position)};
  z-index: ${theme.zIndices.label};
`

const labelStyling = css`
  border: ${theme.borders.base} ${theme.colors.borderDark};
  font-size: ${theme.fontSizes.sm};
`

interface Props {
  productLabels: ProductLabelListFragment[]
  gridLayoutType?: GridLayoutTypeEnum
}

const ProductLabels = ({
  productLabels,
  gridLayoutType = GridLayoutTypeEnum.ROW,
  ...other
}: Props) => {
  const labels = productLabels.flatMap((labelLists) => labelLists.items)
  const groupedLabels: GroupedProductLabels[] = groupArrayByProperty(
    labels,
    (label) => label?.position ?? '',
  )

  return (
    <>
      {groupedLabels.map((labelGroup) => {
        if (!labelGroup.groupItems.some((groupItem) => groupItem.isVisible)) {
          return null
        }

        return (
          <LabelGroup
            key={labelGroup.groupName}
            position={labelGroup.groupName}
            gridLayoutType={gridLayoutType}
            {...other}
          >
            {labelGroup.groupItems.map((label) => {
              if (!label.isVisible) {
                return null
              }

              const overrideStyling = `padding: ${theme.spacing.xs} ${theme.spacing.sm}; margin: 0; width: max-content;`

              return (
                <div
                  key={label.labelId}
                  css={labelStyling}
                  // Ignore STYLE type error, do not remove
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  STYLE={
                    label.style
                      ? label.style?.concat(overrideStyling)
                      : overrideStyling
                  }
                >
                  {label.txt || label.name}
                </div>
              )
            })}
          </LabelGroup>
        )
      })}
    </>
  )
}

export default ProductLabels
