import { useIsLoggedIn } from '@emico-hooks/login-token'
import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import { ButtonUnstyled } from '@emico-react/buttons'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { NextRouter, useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { matchPath } from 'react-router-dom'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { Maybe } from '@emico/graphql-schema-types'
import { stripMaybes } from '@emico/utils'

import AccountIcon from '../icons/AccountIcon'
import getCategoryLink from '../lib/getCategoryLink'
import { getCurrentStoreCountry } from '../lib/getStoreSelectorCountries'
import { routes } from '../lib/routes'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import CraftImage from './CraftImage'
import CraftLink from './CraftLink'
import MenuMobileHeader from './MenuMobileHeader'
import MobileMenuItemWithChildren from './MobileMenuItemWithChildren'
import NextLink from './NextLink'

const isPathActive = (router: NextRouter, categoryLink: string) =>
  Boolean(matchPath(router.asPath, categoryLink))

const Menu = styled('div', {
  shouldForwardProp: (prop) => !['isSubOpen'].includes(prop.toString()),
})<{ isSubOpen: boolean }>`
  display: flex;
  flex-direction: column;
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.slow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  width: 100%;
  height: 100vh;
  background-color: ${theme.colors.background};
  overscroll-behavior: contain;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  overscroll-behavior: contain;
  overflow: auto;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  margin: 0 ${theme.spacing.md};
`

const mainLinkStyling = css`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.md} 0;
  border-bottom: ${theme.borders.default};
  color: ${theme.colors.text};
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: currentColor;
  }
`

const StyledAccountIcon = styled(AccountIcon)`
  font-size: 16px;
  margin-right: ${theme.spacing.sm};
`

const StyledNextLink = styled(NextLink, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop.toString()),
})<{ isActive?: boolean }>`
  ${mainLinkStyling};
  color: ${({ isActive }) => isActive && theme.colors.primary};

  &:hover {
    color: ${({ isActive }) => isActive && theme.colors.primary};
  }
`

const MainNextLink = styled(StyledNextLink)`
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const MainLinkCraftLink = styled(CraftLink)`
  ${mainLinkStyling};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const ServiceLinkCraftLink = styled(CraftLink)`
  ${mainLinkStyling};
`

const StyledCraftImage = styled(CraftImage)`
  margin-right: ${theme.spacing.sm};
  flex-shrink: 0;
`

const CountryButton = styled(ButtonUnstyled)`
  display: grid;
  align-items: center;
  grid-template-columns: 16px auto;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.md} 0;
`

interface Props extends DefaultLayoutStaticData {
  rootCategory: CategoryFragmentWithChildren
  show: boolean
  close: () => void
  setOpenStoreSelectorModal: (openStoreSelectorModal: boolean) => void
}

const MenuMobile = ({
  websiteData,
  rootCategory,
  show,
  close,
  setOpenStoreSelectorModal,
}: Props) => {
  const router = useRouter()
  const isLoggedIn = useIsLoggedIn()
  const activeStoreView = useActiveStoreView()
  const [isSubOpen, setIsSubOpen] = useState<boolean>(false)
  const [activeCategory, setActiveCategory] =
    useState<CategoryFragmentWithChildren | null>(null)

  const currentStoreCountry = getCurrentStoreCountry(activeStoreView.code)

  const rootCategoryChildren =
    rootCategory?.children
      ?.filter(stripMaybes)
      .filter((category) => category?.includeInMenu === 1)
      .sort((a, b) => (a?.position || 0) - (b?.position || 0)) ?? []

  const menuGlobalSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftMenuGlobalSet',
  )

  const menuImage =
    menuGlobalSet?.__typename === 'CraftMenuGlobalSet'
      ? menuGlobalSet.image?.[0]
      : undefined

  const menuLinks =
    menuGlobalSet?.__typename === 'CraftMenuGlobalSet'
      ? menuGlobalSet.menuLinks
      : undefined

  const menuIconLinks =
    menuGlobalSet?.__typename === 'CraftMenuGlobalSet'
      ? menuGlobalSet.iconLinks
      : undefined

  const menuItemIds =
    menuGlobalSet?.__typename === 'CraftMenuGlobalSet'
      ? menuGlobalSet.menuItems?.map((item) => item?.categoryId) ?? []
      : []

  const handleCategoryClick = (category: CategoryFragmentWithChildren) => {
    setIsSubOpen(true)
    setActiveCategory(category)
  }

  const handleCloseMenu = () => {
    close()
    setIsSubOpen(false)
  }

  useEffect(() => {
    if (!show) {
      setIsSubOpen(false)
    }
  }, [show])

  useEffect(() => {
    handleCloseMenu()
    // A navigation event occurs when the router asPath changes or locale changes due to store change,
    // a navigation event should close the menu (e.g. menu item clicks, back button etc).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, router.locale])

  return (
    <Menu isSubOpen={isSubOpen}>
      <MenuMobileHeader
        title={t({
          id: 'header.mobile.menuTitle',
          message: 'Menu',
        })}
        image={menuImage}
        close={handleCloseMenu}
      />

      <Nav>
        <List>
          {rootCategoryChildren.map(
            (category: Maybe<CategoryFragmentWithChildren>) => {
              if (!category?.name) {
                return null
              }

              const categoryLink = getCategoryLink(category)
              const hasChildren = menuItemIds.includes(category.id)
              const categoryIsActive = isPathActive(router, categoryLink)
              const submenuItem =
                menuGlobalSet?.__typename === 'CraftMenuGlobalSet'
                  ? menuGlobalSet.menuItems?.find(
                      (item) => item?.categoryId === category.id,
                    )?.menuItem?.[0]
                  : undefined
              const isSubActive = category.id === activeCategory?.id

              return hasChildren ? (
                <MobileMenuItemWithChildren
                  key={category.id}
                  category={category}
                  categoryIsActive={categoryIsActive}
                  handleCategoryClick={handleCategoryClick}
                  submenuItem={
                    submenuItem?.__typename === 'CraftMenuItemEntry'
                      ? submenuItem
                      : null
                  }
                  close={close}
                  back={() => setIsSubOpen(false)}
                  isSubOpen={isSubOpen}
                  isSubActive={isSubActive}
                  isMenuOpen={show}
                />
              ) : (
                <ListItem key={category.id}>
                  <MainNextLink
                    analyticsContext="header.mobile.mainLinks"
                    analyticsName={category.name}
                    isActive={categoryIsActive}
                    href={categoryLink}
                  >
                    {category.name}
                  </MainNextLink>
                </ListItem>
              )
            },
          )}

          {menuLinks?.length !== 0 &&
            menuLinks?.map((link, index) =>
              link?.linkItem?.url && link.linkItem.customText ? (
                <ListItem key={index}>
                  <MainLinkCraftLink
                    analyticsContext="header.desktop.mainLinks"
                    analyticsName={link?.linkItem?.url}
                    craftLink={link.linkItem}
                  >
                    {link.linkItem.customText}
                  </MainLinkCraftLink>
                </ListItem>
              ) : null,
            )}
        </List>

        <List>
          <ListItem>
            {isLoggedIn ? (
              <StyledNextLink
                href={routes.account.orderHistory.base}
                analyticsContext="header.mobile"
                analyticsName="account.orderHistory"
              >
                <StyledAccountIcon />

                <Trans>My account</Trans>
              </StyledNextLink>
            ) : (
              <StyledNextLink
                href={routes.account.login}
                analyticsContext="header.mobile"
                analyticsName="account.logIn"
              >
                <StyledAccountIcon />

                <Trans>Log in / create account</Trans>
              </StyledNextLink>
            )}
          </ListItem>

          {menuIconLinks?.length !== 0 &&
            menuIconLinks?.map((link, index) => {
              const icon = link?.linkIcon?.[0]

              return link?.linkItem?.url && link.linkItem.customText ? (
                <ListItem key={index}>
                  <ServiceLinkCraftLink
                    analyticsContext="header.desktop.mainLinks"
                    analyticsName={link?.linkItem?.url}
                    craftLink={link.linkItem}
                  >
                    {icon && <StyledCraftImage image={icon} width={18} />}

                    {link.linkItem.customText}
                  </ServiceLinkCraftLink>
                </ListItem>
              ) : null
            })}

          <ListItem>
            <CountryButton
              analyticsContext="header.mobile"
              analyticsName="store.selector.change.country"
              onPress={() => {
                handleCloseMenu()
                setOpenStoreSelectorModal(true)
              }}
            >
              {currentStoreCountry?.flag}

              {currentStoreCountry?.name}
            </CountryButton>
          </ListItem>
        </List>
      </Nav>
    </Menu>
  )
}

export default MenuMobile
