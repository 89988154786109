import styled from '@emotion/styled'
import { useFocusManager } from '@react-aria/focus'
import React, { useEffect } from 'react'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'

import { useStoreConfig } from '../lib/storeConfig'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import PreviouslyViewedProducts from './PreviouslyViewedProducts'
import SearchMobileModalHeader from './SearchMobileModalHeader'
import SearchSuggestionsForm from './SearchSuggestionsForm'

const ModalBody = styled.section`
  background: ${theme.colors.background};
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: stretch;
`

const StyledPreviouslyViewedProducts = styled(PreviouslyViewedProducts)`
  margin: ${theme.spacing.lg} 0 ${theme.spacing['3xl']} ${theme.spacing.lg};
`

interface Props extends DefaultLayoutStaticData {
  close: () => void
  rootCategory: CategoryFragmentWithChildren
}

const SearchMobileModalContent = ({
  close,
  websiteData,
  rootCategory,
}: Props) => {
  const { storeConfig } = useStoreConfig()
  const focusManager = useFocusManager()
  const disableEcommerce = storeConfig?.disableEcommerce ?? false

  /**
   * Put autofocus on search bar input
   */
  useEffect(() => {
    focusManager.focusLast()
  }, [focusManager])

  return (
    <ModalBody>
      <div>
        <SearchMobileModalHeader close={close} />

        <SearchSuggestionsForm
          rootCategory={rootCategory}
          websiteData={websiteData}
          handleClose={close}
          isSimpleMobile
        />
      </div>

      <StyledPreviouslyViewedProducts disableEcommerce={disableEcommerce} />
    </ModalBody>
  )
}

export default SearchMobileModalContent
