import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import { ComponentSlider } from '@emico-react/component-slider'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { ComponentProps, useState } from 'react'

import { ProductStockStatus } from '@emico/graphql-schema-types'
import { PlusIcon } from '@emico/icons'
import { minWidth, maxWidth } from '@emico/styles'

import { AlertProps } from '../lib/customTypes'
import getIsPreorder from '../lib/getIsPreorder'
import { useWishlist } from '../lib/useWishlist'
import theme from '../theme'
import Alert from './Alert'
import ButtonSecondary from './ButtonSecondary'
import ConfigurableAddToCartForm from './ConfigurableAddToCartForm'
import ProductCardSimple from './ProductCardSimple'
import { WishlistIconButton } from './WishlistIconButton'

const Slide = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledProductCardSimple = styled(ProductCardSimple)`
  flex-grow: 1;
`

const StyledButtonSecondary = styled(ButtonSecondary)`
  margin-top: ${theme.spacing.md};
`

const StyledConfigurableAddToCartForm = styled(ConfigurableAddToCartForm)`
  margin-top: ${theme.spacing.md};
`

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: ${theme.spacing.xs};
`

const StyledAlert = styled(Alert)`
  margin-top: ${theme.spacing.lg};
`

interface SliderProps
  extends Omit<ComponentProps<typeof ComponentSlider>, 'children'> {
  disableEcommerce: boolean
  products?: ProductCardFragment[]
  showAddToCartButton: boolean
}

const Slider = ({
  disableEcommerce,
  products,
  showAddToCartButton,
  ...other
}: SliderProps) => {
  const [cartAlert, setCartAlert] = useState<AlertProps | null>(null)

  const handleCartStateChange = (cartAlert: AlertProps | null) => {
    setCartAlert(cartAlert)
  }

  const { getItemBySku, wishlist } = useWishlist()

  return (
    <>
      <ComponentSlider snapAlign="start" slideGap={15} {...other}>
        {products?.filter(stripMaybes).map((product, index) => {
          const wishlistItem = getItemBySku(product.sku)
          const isOutOfStock =
            product.stockStatus === ProductStockStatus.OUT_OF_STOCK
          const isPreorder = getIsPreorder(product.preorder)
          const isMainProduct =
            product?.isMainProduct ||
            product?.__typename === 'ConfigurableProduct'

          return (
            <Slide key={`${product.name}-${index}`}>
              <StyledProductCardSimple
                wishlistIcon={
                  <WishlistIconButton
                    product={product}
                    wishlistId={wishlist?.id ?? ''}
                    wishlistItem={wishlistItem}
                    analyticsContext="product.slider"
                    analyticsName="wishlist"
                  />
                }
                name={product.name}
                url={`/${product.urlKey}`}
                regularPrice={product.priceRange.minimumPrice.regularPrice}
                finalPrice={product.priceRange.minimumPrice.finalPrice}
                reviewRating={product.ratingSummary ?? 0}
                reviewCount={product.reviewCount ?? 0}
                percentOff={
                  product.priceRange.minimumPrice.discount?.percentOff
                }
                image={{
                  url: product.smallImage?.url ?? '',
                  alt: product.name,
                }}
                productLabels={product.productLabels?.filter(stripMaybes)}
                showProductPrice={!isMainProduct}
                analyticsContext="productSlider"
              />

              {showAddToCartButton &&
                (product.__typename === 'ConfigurableProduct' ||
                disableEcommerce ? (
                  <StyledButtonSecondary
                    analyticsContext="product.slider"
                    analyticsName="show.product"
                    colorTheme="dark"
                    href={product.urlKey}
                  >
                    <Trans>Show</Trans>
                  </StyledButtonSecondary>
                ) : (
                  <StyledConfigurableAddToCartForm
                    isOutOfStock={isOutOfStock}
                    product={product}
                    handleCartStateChange={handleCartStateChange}
                    customAddToCartText={
                      <>
                        <StyledPlusIcon />

                        {isPreorder ? (
                          <Trans>Pre-order</Trans>
                        ) : (
                          <Trans>Add</Trans>
                        )}
                      </>
                    }
                    showButtonsOnly
                    hideWishlistButton
                    addToCartButtonColorType="neutral"
                  />
                ))}
            </Slide>
          )
        })}
      </ComponentSlider>

      <StyledAlert type={cartAlert?.type}>{cartAlert?.message}</StyledAlert>
    </>
  )
}

const StyledSliderMobile = styled(Slider)`
  @media ${minWidth('md')} {
    display: none;
  }
`

const StyledSliderTablet = styled(Slider)`
  @media ${maxWidth('sm')} {
    display: none;
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledSliderDesktop = styled(Slider)`
  @media ${maxWidth('md')} {
    display: none;
  }
`

interface Props {
  disableEcommerce: boolean
  products: ProductCardFragment[]
  slidesToShowMobile: number
  slidesToShowTablet: number
  slidesToShowDesktop: number
  showAddToCartButton?: boolean
}

const ProductSlider = ({
  disableEcommerce,
  products,
  slidesToShowMobile,
  slidesToShowTablet,
  slidesToShowDesktop,
  showAddToCartButton = false,
  ...other
}: Props) => {
  const itemCount = products.length

  return (
    <div {...other}>
      <StyledSliderMobile
        disableEcommerce={disableEcommerce}
        products={products}
        slidesToShow={slidesToShowMobile}
        showAddToCartButton={showAddToCartButton}
      />

      <StyledSliderTablet
        disableEcommerce={disableEcommerce}
        products={products}
        slidesToShow={slidesToShowTablet}
        showAddToCartButton={showAddToCartButton}
      />

      <StyledSliderDesktop
        disableEcommerce={disableEcommerce}
        products={products}
        slidesToShow={slidesToShowDesktop}
        showAddToCartButton={showAddToCartButton}
        showArrows={itemCount > slidesToShowDesktop}
      />
    </div>
  )
}

export default ProductSlider
