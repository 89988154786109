import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import React from 'react'

import { minWidth } from '@emico/styles'
import { H3, H4 } from '@emico/ui'

import { CraftGenericBlockLinkFragment } from '../lib/craftFragments.generated'
import { CraftImageType } from '../lib/customTypes'
import theme from '../theme'
import CraftLink from './CraftLink'

export const StyledCraftLink = styled(CraftLink)`
  height: 300px;
  width: 300px;
  display: grid;
  align-items: end;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.zoomNestedImage(1.1, theme.transition.durations.normal)};
  }
`

const Figure = styled.figure`
  margin: 0;
  height: 100%;
  grid-row: 1;
  grid-column: 1;
  ${theme.stylingSnippet.transparentGradientOverlay()};
`

const StyledPicture = styled(Picture)`
  width: 100%;
  height: auto;
  border-radius: ${theme.borderRadius.base};
`

const Header = styled.header`
  grid-row: 1;
  grid-column: 1;
  padding: ${theme.spacing.md};
  position: relative;
  z-index: 1;

  @media ${minWidth('md')} {
    padding: ${theme.spacing.lg};
  }
`

const Title = styled(H3)`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  color: ${theme.colors.textLight};
`

const Subtitle = styled(H4)`
  color: ${theme.colors.textLight};
`

interface Props {
  item: CraftGenericBlockLinkFragment
  onClick?: () => void
}

const CraftGenericBlockLink = ({ item, ...other }: Props) => {
  const image = item.image?.[0] as CraftImageType

  if (!image || !item.linkField) {
    return null
  }

  return (
    <article {...other}>
      <StyledCraftLink
        craftLink={item.linkField}
        analyticsContext="newsItem"
        analyticsName={`item.${item.title}`}
      >
        <Figure>
          <StyledPicture
            style={{}}
            breakpoints={{
              mobile: {
                url:
                  ('mobileUrl' in image ? image.mobileUrl : image.defaultUrl) ??
                  '',
                width: theme.imageSizes.square.sizes.xs,
                height: theme.imageSizes.square.sizes.xs,
              },
              tablet: {
                url:
                  ('tabletUrl' in image ? image.tabletUrl : image.defaultUrl) ??
                  '',
                width: theme.imageSizes.square.sizes.md,
                height: theme.imageSizes.square.sizes.md,
              },
              desktop: {
                url:
                  ('desktopUrl' in image
                    ? image.desktopUrl
                    : image.defaultUrl) ?? '',
                width: theme.imageSizes.square.width,
                height: theme.imageSizes.square.width,
              },
            }}
            alt={image.title ?? ''}
            lazy={false}
          />
        </Figure>

        <Header>
          {item.title && <Title>{item.title}</Title>}

          {item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
        </Header>
      </StyledCraftLink>
    </article>
  )
}

export default CraftGenericBlockLink
