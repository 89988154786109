import React, { SVGProps } from 'react'

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 9"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M1 2a1 1 0 011.64-.77L8 5.71l5.37-4.32a1 1 0 011.41.15A1 1 0 0114.63 3l-6 4.83a1 1 0 01-1.27 0l-6-5A1 1 0 011 2z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronDownIcon
