import { ProductParentFragment } from '@emico-hooks/product-fragment'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { FieldValues } from 'react-hook-form'

import { ProductStockStatus } from '@emico/graphql-schema-types/src'

import getIsPreorder from './getIsPreorder'
import { getProductVariantsBasedOnSelectedOptions } from './getProductVariantsBasedOnSelectedOptions'

interface PreOrder {
  atpDate: string
  atpDateFormatted: string
  isPreorder: boolean
}

/**
 * Function to convert parent-based configurable options into a renderable format
 *
 * @param productParentData Current product's parent data
 * @param formValues Selected configurable options
 * @returns Renderable attribute option object
 */

export function getRenderableConfigurableOptions(
  productParentData: ProductParentFragment | undefined | null,
  formValues: FieldValues,
) {
  const selectedConfigurableOptions = Object.values(formValues).filter(
    (value) => value !== '',
  )

  const parentProduct =
    productParentData?.product &&
    'configurableOptions' in productParentData.product
      ? productParentData.product
      : undefined

  const parentProductConfigurableOptions =
    productParentData?.product &&
    'configurableOptions' in productParentData.product
      ? productParentData.product.configurableOptions?.filter(stripMaybes) ?? []
      : []

  const parentProductVariantsInStock =
    parentProduct?.variants
      ?.filter(
        (variant) =>
          variant?.product?.stockStatus === ProductStockStatus.IN_STOCK,
      )
      .filter(stripMaybes) ?? []

  const getOptionAvailability = (
    optionValueUid: string,
    selectedConfigurableOptionsExcludingCurrent: string[],
  ) => {
    const availableParentProductVariantsBasedOnSelectedOptions =
      getProductVariantsBasedOnSelectedOptions(
        parentProductVariantsInStock,
        selectedConfigurableOptionsExcludingCurrent,
      )

    return (
      !availableParentProductVariantsBasedOnSelectedOptions?.some(
        (availableProductVariant) =>
          availableProductVariant?.attributes?.some(
            (attribute) => attribute?.uid === optionValueUid,
          ),
      ) || false
    )
  }

  const getOptionPreorderStatus = (
    optionValueUid: string,
    selectedConfigurableOptionsExcludingCurrent: string[],
  ) => {
    const availableParentProductVariantsBasedOnSelectedOptions =
      getProductVariantsBasedOnSelectedOptions(
        parentProductVariantsInStock,
        selectedConfigurableOptionsExcludingCurrent,
      )

    const variantsWithCurrentOptionValue =
      availableParentProductVariantsBasedOnSelectedOptions.filter((variant) =>
        variant?.attributes?.some(
          (attribute) => attribute?.uid === optionValueUid,
        ),
      )

    const variantResult =
      variantsWithCurrentOptionValue.length === 1
        ? variantsWithCurrentOptionValue[0]
        : undefined

    const variantResultPreorder =
      variantResult?.product && 'preorder' in variantResult.product
        ? (variantResult.product.preorder as PreOrder)
        : undefined

    return variantResultPreorder ? getIsPreorder(variantResultPreorder) : false
  }

  return parentProductConfigurableOptions?.map((option) => {
    const selectedOptionValue = option.values?.find((optionValue) =>
      selectedConfigurableOptions.includes(optionValue?.uid),
    )?.uid

    const selectedConfigurableOptionsExcludingCurrent =
      selectedConfigurableOptions.filter(
        (selectedOption) => selectedOption !== selectedOptionValue,
      )

    return {
      key: option.attributeCode ?? '',
      code: option.attributeCode ?? '',
      label: option.label,
      position: option.position,
      values: option.values?.filter(stripMaybes)?.map((value) => {
        const isOptionAvailable = value.uid
          ? getOptionAvailability(
              value.uid,
              selectedConfigurableOptionsExcludingCurrent,
            )
          : false

        const isValueSelected = selectedConfigurableOptions.includes(value.uid)

        const isOptionPreorder = value.uid
          ? getOptionPreorderStatus(
              value.uid,
              selectedConfigurableOptionsExcludingCurrent,
            )
          : false

        return {
          key: value.uid ?? '',
          label: value.label ?? '',
          attributeUid: option.attributeCode ?? '',
          swatch: value.swatchData,
          disabled: isOptionAvailable,
          isPreorder: !isValueSelected && isOptionPreorder,
        }
      }),
    }
  })
}
