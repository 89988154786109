import { usePreviouslyUsedSearchTerms } from '@emico-hooks/previously-used-search-terms'
import { TweakwiseSuggestionProductsResultFragment } from '@emico-hooks/tweakwise-suggestion-products'
import { TweakwiseSuggestionsResultFragment } from '@emico-hooks/tweakwise-suggestions'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import React from 'react'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { maxWidth, minWidth } from '@emico/styles'
import { Loader } from '@emico/ui'

import { CraftLinkFragment } from '../lib/craftFragments.generated'
import { routes } from '../lib/routes'
import Unwrap from '../lib/UnWrap'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import Alert from './Alert'
import ButtonSecondary from './ButtonSecondary'
import CraftImage from './CraftImage'
import CraftLink from './CraftLink'
import Modal, {
  ModalBody,
  Content as ModalContent,
  StyledModalBackdrop,
  StyledModalSegue,
} from './Modal'
import NextLink from './NextLink'
import ProductCardSimple, {
  ProductName as ProductCardProductName,
  StyledRegularFinalPrice as ProductCardPrice,
} from './ProductCardSimple'

enum SuggestionType {
  SEARCH_PHRASE = 'SearchPhrase',
  CATEGORY = 'Category',
}

const ModalWrapper = styled.div`
  ${StyledModalBackdrop} {
    top: calc((${theme.sizes.headerHeight} + ${theme.sizes.uspBarHeight}));
  }

  ${StyledModalSegue} {
    @media ${minWidth('lg')} {
      position: absolute;
    }
  }
`

const StyledModal = styled(Modal)`
  ${ModalBody} {
    height: calc(
      100% - var(--sizes-full-screen-modal-header-height) -
        var(--sizes-search-input-height)
    );
    top: calc(
      var(--sizes-full-screen-modal-header-height) +
        var(--sizes-search-input-height)
    );

    @media ${minWidth('lg')} {
      height: auto;
      width: 100%;
      max-width: none;
      transform: none;
      left: 0;
      top: ${theme.sizes.headerHeight};
    }
  }

  ${ModalContent} {
    padding-top: ${theme.spacing.lg};
  }
`

const StyledAlert = styled(Alert)`
  margin-bottom: ${theme.spacing.lg};
`

const Grid = styled.div`
  display: grid;
  gap: ${theme.spacing.xl};

  @media ${minWidth('lg')} {
    gap: ${theme.spacing.sm};
    grid-template-columns: repeat(12, 1fr);
  }
`

const SuggestionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xl};

  @media ${minWidth('lg')} {
    grid-column: 1 / span 2;
  }
`

const ProductsColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${theme.spacing.md};

  @media ${minWidth('md')} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${minWidth('lg')} {
    grid-column: 3 / span 12;
    grid-template-columns: repeat(5, 1fr);
    gap: ${theme.spacing.sm};
  }
`

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  background-color: ${theme.colors.backgroundLight};
`

const StyledLoader = styled(Loader)`
  border-width: 3px;
  width: 20px;
  height: 20px;
  border-color: currentColor;
  border-left-color: transparent;
`

const StyledProductCardSimple = styled(ProductCardSimple)`
  ${ProductCardProductName}, ${ProductCardPrice} {
    font-size: ${theme.fontSizes.sm};
  }
`

const ButtonWrapper = styled.div`
  margin: 0 ${theme.spacing.lg} ${theme.spacing.lg};
`

const StyledButtonSecondary = styled(ButtonSecondary)`
  width: 100%;
  padding-left: ${theme.spacing.lg};
  padding-right: ${theme.spacing.lg};
`

const SuggestionTitle = styled('span', {
  shouldForwardProp: (prop) => !['hasSmallTitle'].includes(String(prop)),
})<{
  hasSmallTitle?: boolean
}>`
  display: block;
  font-size: ${theme.fontSizes.md};
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: ${theme.spacing.sm};

  @media ${minWidth('lg')} {
    font-size: ${({ hasSmallTitle }) => hasSmallTitle && theme.fontSizes.sm};
  }
`

const SuggestionList = styled('ul', {
  shouldForwardProp: (prop) => !['columnCount'].includes(String(prop)),
})<{
  columnCount?: number
}>`
  display: grid;
  grid-template-columns: ${({ columnCount }) =>
    columnCount && `repeat(${columnCount}, 1fr)`};
  gap: ${theme.spacing.sm};
  margin: 0;
  padding: 0;
  list-style: none;
`

const linkStyling = css`
  text-decoration: none;
  color: ${theme.colors.text};

  &:hover {
    color: ${theme.colors.text};
    text-decoration: none;
  }
`

const NextLinkSimple = styled(NextLink)`
  ${linkStyling};

  @media ${maxWidth('md')} {
    text-decoration: underline;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover()};
  }
`

const StyledCraftLink = styled(CraftLink)`
  ${linkStyling}
`

const CraftLinkSimple = styled(StyledCraftLink)`
  @media ${maxWidth('md')} {
    text-decoration: underline;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover()};
  }
`

const CraftLinkCard = styled(StyledCraftLink)`
  text-align: center;

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover('currentColor', '1px', 'span')};
  }
`

const StaticSearchSuggestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xl};
  max-width: 800px;
  margin: 0 auto;
`

const PopularSearchTermsGrid = styled.div`
  display: grid;

  @media ${minWidth('lg')} {
    gap: ${theme.spacing.sm};
    grid-template-columns: repeat(6, 1fr);
  }
`

const PopularSearchTermsTitle = styled.span`
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  display: block;
  margin-bottom: ${theme.spacing.md};

  @media ${minWidth('lg')} {
    font-size: ${theme.fontSizes.sm};
  }
`

const Figure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing.xs};
  padding: ${theme.spacing.sm} ${theme.spacing.xs};
  aspect-ratio: 1 / 1;
  background-color: ${theme.colors.backgroundLight};
`

const StyledCraftImage = styled(CraftImage)`
  height: 50px;
  width: auto;
  aspect-ratio: 16/9;
  object-fit: contain;
`

const SearchTerm = styled.span`
  font-size: ${theme.fontSizes.xs};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  line-break: anywhere;
`

type TweakwiseSuggestionsResult = Exclude<
  TweakwiseSuggestionsResultFragment['suggestions'],
  null
>

type TweakwiseSuggestion = Unwrap<TweakwiseSuggestionsResult>

function getSuggestionMatches(
  suggestions: Exclude<TweakwiseSuggestion['suggestions'], null>,
) {
  return suggestions.map((suggestion) => ({
    title: suggestion.match,
  }))
}

interface Suggestion {
  title: string
  craftLink?: CraftLinkFragment
}

interface SuggestionBlockProps {
  title: string
  suggestions: Suggestion[]
  rootCategory?: CategoryFragmentWithChildren
  close: () => void
  columnCount?: number
  hasSmallTitle?: boolean
}

const SuggestionBlock = ({
  title,
  suggestions,
  rootCategory,
  close,
  columnCount,
  hasSmallTitle,
}: SuggestionBlockProps) => (
  <div>
    <SuggestionTitle hasSmallTitle={hasSmallTitle}>{title}</SuggestionTitle>

    <SuggestionList columnCount={columnCount}>
      {suggestions.map((suggestion) => {
        const suggestionCategory = rootCategory?.children?.find(
          (category) => category?.name === suggestion.title,
        )

        const suggestionHref = suggestionCategory
          ? `/${
              suggestionCategory.canonicalUrl ??
              suggestionCategory.urlPath ??
              suggestionCategory.urlKey
            }`
          : {
              pathname: routes.search.base,
              query: {
                query: suggestion.title,
              },
            }

        return (
          <li key={suggestion.title}>
            {suggestion.craftLink ? (
              <CraftLinkSimple
                analyticsContext="search.suggestions.modal"
                analyticsName={suggestion.title}
                craftLink={suggestion.craftLink}
                onClick={close}
              >
                {suggestion.title}
              </CraftLinkSimple>
            ) : (
              <NextLinkSimple
                analyticsContext="search.suggestions.modal"
                analyticsName={suggestion.title}
                href={suggestionHref}
                onClick={close}
              >
                {suggestion.title}
              </NextLinkSimple>
            )}
          </li>
        )
      })}
    </SuggestionList>
  </div>
)

interface FormValues {
  searchQuery: string
}

interface Props extends DefaultLayoutStaticData {
  /**
   * show modal state
   */
  show: boolean
  /**
   * close modal function
   */
  close: () => void
  /**
   * Function to submit search suggestions form
   */
  handleSuggestionsSubmit: (values: FormValues) => void
  /**
   * Current search query
   */
  searchQueryValue: string
  /**
   * Root category list
   */
  rootCategory: CategoryFragmentWithChildren
  /**
   * Tweakwise suggestions
   */
  tweakwiseSuggestions: TweakwiseSuggestionsResult | undefined
  /**
   * Tweakwise suggestion products
   */
  tweakwiseSuggestionProducts:
    | TweakwiseSuggestionProductsResultFragment
    | undefined
  /**
   * Is tweakwise suggestion products query still loading?
   */
  isSuggestionProductsLoading: boolean
  /**
   * Does it concern a simple mobile lay-out?
   */
  isSimpleMobile?: boolean
  /**
   * Error string for retrieving suggestions
   */
  suggestionsError?: string
}

const SearchSuggestionsModal = ({
  show,
  close,
  handleSuggestionsSubmit,
  websiteData,
  searchQueryValue,
  rootCategory,
  tweakwiseSuggestions,
  tweakwiseSuggestionProducts,
  isSuggestionProductsLoading,
  isSimpleMobile = false,
  suggestionsError,
}: Props) => {
  const previouslyUsedSearchTerms = usePreviouslyUsedSearchTerms()

  const hasSuggestions =
    (tweakwiseSuggestions && tweakwiseSuggestions.length !== 0) ||
    (tweakwiseSuggestionProducts &&
      tweakwiseSuggestionProducts?.items.length !== 0)

  const categorySuggestions = tweakwiseSuggestions?.find(
    (suggestion) => suggestion.type === SuggestionType.CATEGORY,
  )

  const searchPhraseSuggestions = tweakwiseSuggestions?.find(
    (suggestion) => suggestion.type === SuggestionType.SEARCH_PHRASE,
  )

  const productSuggestions = tweakwiseSuggestionProducts?.items
  const productSuggestionsCount = productSuggestions?.length

  const craftSearchSuggestionsGlobalSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftSearchSuggestionsGlobalSet',
  )

  const craftSearchSuggestions =
    craftSearchSuggestionsGlobalSet?.__typename ===
    'CraftSearchSuggestionsGlobalSet'
      ? {
          title: craftSearchSuggestionsGlobalSet.popularSearchTermsTitle,
          terms: craftSearchSuggestionsGlobalSet.popularSearchTerms,
        }
      : undefined
  const craftSearchSuggestionsCount = craftSearchSuggestions?.terms.length

  const popularSearchTermsTitle =
    craftSearchSuggestions?.title ?? t({ message: 'Popular search terms' })

  return (
    <ModalWrapper>
      <StyledModal
        show={show}
        close={close}
        hasModalHeader={false}
        showMobileBackdrop={false}
        bottom={
          isSimpleMobile &&
          hasSuggestions && (
            <ButtonWrapper>
              <StyledButtonSecondary
                analyticsContext="search.suggestions.modal"
                analyticsName="mobile.show.results"
                onClick={() =>
                  handleSuggestionsSubmit({
                    searchQuery: searchQueryValue,
                  })
                }
                colorTheme="dark"
              >
                <Trans>Show all search results</Trans>
              </StyledButtonSecondary>
            </ButtonWrapper>
          )
        }
      >
        <div>
          {suggestionsError && (
            <StyledAlert type="error">{suggestionsError}</StyledAlert>
          )}

          {hasSuggestions ? (
            <Grid>
              <SuggestionsColumn>
                {searchPhraseSuggestions?.suggestions &&
                  searchPhraseSuggestions.suggestions.length !== 0 && (
                    <SuggestionBlock
                      title={t({ message: 'Search suggestions' })}
                      suggestions={getSuggestionMatches(
                        searchPhraseSuggestions.suggestions,
                      )}
                      close={close}
                    />
                  )}

                {categorySuggestions?.suggestions &&
                  categorySuggestions.suggestions.length !== 0 && (
                    <SuggestionBlock
                      title={t({ message: 'Categories' })}
                      suggestions={getSuggestionMatches(
                        categorySuggestions.suggestions,
                      )}
                      rootCategory={rootCategory}
                      close={close}
                    />
                  )}
              </SuggestionsColumn>

              {productSuggestionsCount !== 0 && (
                <ProductsColumn>
                  {productSuggestions?.map((item) => {
                    const product = item.product

                    if (!product) {
                      return null
                    }

                    if (isSuggestionProductsLoading) {
                      return (
                        <LoaderWrapper key={product.name}>
                          <StyledLoader />
                        </LoaderWrapper>
                      )
                    }

                    return (
                      <StyledProductCardSimple
                        key={product.name}
                        name={product.name}
                        url={product.urlKey}
                        regularPrice={
                          product.priceRange.minimumPrice.regularPrice
                        }
                        finalPrice={product.priceRange.minimumPrice.finalPrice}
                        percentOff={
                          product.priceRange.minimumPrice.discount?.percentOff
                        }
                        image={{
                          url: product.smallImage?.url ?? '',
                          alt: product.name,
                        }}
                        productLabels={product.productLabels?.filter(
                          stripMaybes,
                        )}
                        analyticsContext="search.suggestion"
                      />
                    )
                  })}
                </ProductsColumn>
              )}
            </Grid>
          ) : (
            <StaticSearchSuggestions>
              {craftSearchSuggestions && craftSearchSuggestionsCount !== 0 && (
                <div>
                  {isSimpleMobile ? (
                    <SuggestionBlock
                      title={popularSearchTermsTitle}
                      suggestions={craftSearchSuggestions.terms
                        .map((suggestion) =>
                          suggestion?.__typename ===
                            'CraftGenericBlockLinkEntry' && suggestion.title
                            ? {
                                title: suggestion.title,
                                craftLink: suggestion.linkField ?? undefined,
                              }
                            : undefined,
                        )
                        .filter(stripMaybes)}
                      close={close}
                    />
                  ) : (
                    <>
                      <PopularSearchTermsTitle>
                        {popularSearchTermsTitle}
                      </PopularSearchTermsTitle>

                      <PopularSearchTermsGrid>
                        {craftSearchSuggestions?.terms.map((term) => {
                          if (
                            term?.__typename !== 'CraftGenericBlockLinkEntry' ||
                            !term.linkField ||
                            !term.image?.[0]
                          ) {
                            return null
                          }

                          const image = term.image[0]

                          return (
                            <CraftLinkCard
                              key={term.title}
                              craftLink={term.linkField}
                              analyticsContext="search.suggestions.modal"
                              analyticsName={`popular.term.${term.title}`}
                              onClick={close}
                            >
                              <Figure>
                                <StyledCraftImage
                                  image={image}
                                  width={150}
                                  height={100}
                                />
                              </Figure>

                              <SearchTerm>{term.title}</SearchTerm>
                            </CraftLinkCard>
                          )
                        })}
                      </PopularSearchTermsGrid>
                    </>
                  )}
                </div>
              )}

              {previouslyUsedSearchTerms.length !== 0 && (
                <SuggestionBlock
                  title={t({ message: 'Recent searches' })}
                  suggestions={previouslyUsedSearchTerms.map((term) => ({
                    title: term,
                  }))}
                  close={close}
                  columnCount={
                    !isSimpleMobile && previouslyUsedSearchTerms.length >= 5
                      ? 2
                      : undefined
                  }
                  hasSmallTitle
                />
              )}
            </StaticSearchSuggestions>
          )}
        </div>
      </StyledModal>
    </ModalWrapper>
  )
}

export default SearchSuggestionsModal
