import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import { Maybe, Money } from '@emico/graphql-schema-types'
import { ProductLabelListFragment } from '@emico/product-label-fragment/src'
import { minWidth } from '@emico/styles'
import { H3 } from '@emico/ui'

import theme from '../theme'
import NextLink from './NextLink'
import ProductCardLink from './ProductCardLink'
import ProductLabels from './ProductLabels'
import RegularFinalPrice from './RegularFinalPrice'
import StarRatingWithTotals from './StarRatingWithTotals'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--product-card-background, ${theme.colors.backgroundLight});
  border-radius: ${theme.borderRadius.base};
  color: var(--product-card-color, ${theme.colors.onBackground});
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions.easeInOut};
`

const StyledProductLabels = styled(ProductLabels)`
  margin: ${theme.spacing.sm};
`

const StyledProductCardLink = styled(ProductCardLink, {
  shouldForwardProp: (prop) => !['hasWishlistIcon'].includes(prop.toString()),
})<{ hasWishlistIcon: boolean }>`
  padding-bottom: var(--product-card-padding, ${theme.spacing.lg});
  padding-top: ${({ hasWishlistIcon }) => !hasWishlistIcon && theme.spacing.md};
  text-decoration: none;
  flex-direction: column;
  display: flex;
  flex: 1;

  @media ${minWidth('lg')} {
    ${theme.animation.zoomNestedImage(
      1.05,
      theme.transition.durations.extraSlow,
    )};
  }

  &:hover {
    text-decoration: none;
  }
`

const Figure = styled.figure`
  margin-bottom: ${theme.spacing.xl};
  background: var(
    --product-card-image-wrapper-background,
    ${theme.colors.grayLight}
  );
  padding: 0 ${theme.spacing.sm};
  text-align: center;
  overflow: hidden;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: contain;
`

const Info = styled.div`
  padding: 0 var(--product-card-padding, ${theme.spacing.lg});
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const StyledStarRatingWithTotals = styled(StarRatingWithTotals)`
  margin-bottom: ${theme.spacing.sm};
  color: ${theme.colors.text};
`

export const ProductName = styled(H3)`
  display: flex;
  flex: 1;
  margin-bottom: ${theme.spacing.xs};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  text-align: left;
`

export const StyledRegularFinalPrice = styled(RegularFinalPrice)`
  --price-font-size: ${theme.fontSizes.md};
  color: ${theme.colors.text};
`

const WishlistIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0;
  padding: ${theme.spacing.md} ${theme.spacing.md} 0;
  background: var(
    --product-card-image-wrapper-background,
    ${theme.colors.grayLight}
  );
`

interface ImageProps {
  url: string
  alt?: string
  width?: number
  height?: number
}

interface Props extends Pick<ComponentProps<typeof NextLink>, 'onClick'> {
  name: string
  wishlistIcon?: ReactNode
  image: ImageProps
  url: string
  regularPrice: Maybe<Money>
  finalPrice: Maybe<Money>
  percentOff?: Maybe<number>
  reviewCount?: number
  reviewRating?: number
  productLabels: ProductLabelListFragment[] | undefined
  showProductPrice?: boolean
  analyticsContext: string
}

const ProductCardSimple = ({
  name,
  wishlistIcon,
  image,
  url,
  regularPrice,
  finalPrice,
  percentOff,
  reviewCount,
  reviewRating,
  productLabels,
  showProductPrice = true,
  analyticsContext,
  onClick,
  ...other
}: Props) => {
  const hasWishlistIcon = Boolean(wishlistIcon)

  return (
    <Card {...other}>
      {productLabels && productLabels.length !== 0 && (
        <StyledProductLabels productLabels={productLabels} />
      )}

      {hasWishlistIcon && (
        <WishlistIconContainer>{wishlistIcon}</WishlistIconContainer>
      )}

      <StyledProductCardLink
        href={url}
        productName={name}
        analyticsContext={analyticsContext}
        hasWishlistIcon={hasWishlistIcon}
        onClick={onClick}
      >
        {image && (
          <Figure>
            <StyledImage
              url={image.url}
              alt={name}
              lazy={false}
              sizes={theme.imageSizes.productCard.sizes}
            />
          </Figure>
        )}

        <Info>
          <ProductName>{name}</ProductName>

          {reviewCount && reviewRating && (
            <StyledStarRatingWithTotals
              value={reviewRating}
              totalRecords={reviewCount}
              showTotalRecordsShort
            />
          )}

          {showProductPrice && (
            <StyledRegularFinalPrice
              regularPrice={regularPrice}
              finalPrice={finalPrice}
              percentOff={percentOff}
            />
          )}
        </Info>
      </StyledProductCardLink>
    </Card>
  )
}

export default ProductCardSimple
