import { ApolloError } from '@apollo/client'
import { useAddToCart } from '@emico-hooks/cart-add-to-cart'
import { CartItemInput } from '@emico-hooks/graphql-schema-types'
import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import {
  ProductFragment,
  ProductParentFragment,
} from '@emico-hooks/product-fragment'
import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import { pushAddToCart } from '@emico-utils/datalayer'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Control,
  FieldValues,
  useForm,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'

import { sortArrayByProperty } from '@emico/utils'

import ShoppingCartPlusIcon from '../icons/ShoppingCartPlusIcon'
import { convertPreselectOptionsToDefaultValues } from '../lib/convertPreselectOptionsToDefaultValues'
import convertProductForTrackingEvent from '../lib/convertProductForTrackingEvent'
import { AlertProps } from '../lib/customTypes'
import getIsPreorder from '../lib/getIsPreorder'
import { getRenderableConfigurableOptions } from '../lib/getRenderableConfigurableOptions'
import { ProductConfigurableOptions } from '../lib/useProductConfigurables'
import { useRedirectToSimpleProduct } from '../lib/useRedirectToSimpleProduct'
import { useWishlist } from '../lib/useWishlist'
import theme from '../theme'
import ButtonPrimary, { ButtonColorType } from './ButtonPrimary'
import { ColorSwatchOptions } from './ColorSwatchOptions'
import FormField from './FormField'
import ImageSwatchOptions from './ImageSwatchOptions'
import NextLinkPrimary from './NextLinkPrimary'
import Select from './Select'
import SelectOption from './SelectOption'
import { WishlistIconButton } from './WishlistIconButton'

export type AddToCartStatus = 'idle' | 'adding' | 'added'

const ConfigurableOptions = styled.div`
  margin-bottom: ${theme.spacing['2xl']};
`

const Configurable = styled.div`
  margin-bottom: ${theme.spacing.lg};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledButtonPrimary = styled(ButtonPrimary)<{
  isIcon?: boolean
}>`
  flex-grow: 1;
  width: ${({ isIcon }) => isIcon && '49px'};
`

const StyledShoppingCartPlusIcon = styled(ShoppingCartPlusIcon)`
  font-size: 25px;
`

const StyledWishlistIconButton = styled(WishlistIconButton)`
  margin-left: ${theme.spacing.sm};
  width: 49px;
  aspect-ratio: 1 / 1;
`

const VariantMessage = styled.div`
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  margin-bottom: ${theme.spacing.lg};
  background-color: ${theme.colors.pink};
  border-radius: ${theme.borderRadius.base};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  opacity: 0;
  animation: fadeIn ${theme.transition.durations.extraSlow}
    ${theme.transition.timingFunctions.cubicBezierSmooth} forwards;
`

interface ConfigurableOptionsProps {
  configurableOptions: ProductConfigurableOptions[]
  control: Control<FieldValues>
  formValues: FieldValues
  register: UseFormRegister<FieldValues>
  resetFormFields: (attributeUid: string) => void
  setValue: UseFormSetValue<FieldValues>
  defaultValues?: FieldValues
}

const ConfigurableForm = ({
  configurableOptions,
  control,
  formValues,
  register,
  resetFormFields,
  defaultValues,
}: ConfigurableOptionsProps) => {
  const sortedConfigurableOptions = sortArrayByProperty(
    configurableOptions,
    (option) =>
      option.position || option.position === 0 ? option.position : null,
  )

  const getCurrentModel = (optionKey: string) =>
    configurableOptions
      .find((configurableOption) => configurableOption.key === optionKey)
      ?.values?.find((value) => formValues[optionKey] === value.key)?.label

  return (
    <ConfigurableOptions>
      {sortedConfigurableOptions?.map((option) => {
        const defaultOptionValue = defaultValues?.[`${option.key}`]

        if (option.code === 'color_visual') {
          return (
            <Configurable key={option.key}>
              <FormField
                label={t({ message: 'Model:' })}
                fieldIdentifier={option.key}
                subLabel={getCurrentModel(option.key)}
              >
                <ImageSwatchOptions
                  register={register}
                  option={option}
                  resetFormFields={resetFormFields}
                  defaultValues={defaultValues}
                />
              </FormField>
            </Configurable>
          )
        }

        if (option.code === 'color') {
          return (
            <Configurable key={option.key}>
              <FormField
                label={t({ message: 'Color' })}
                fieldIdentifier={option.key}
                subLabel={getCurrentModel(option.key)}
              >
                <ColorSwatchOptions
                  register={register}
                  control={control}
                  option={option}
                  resetFormFields={resetFormFields}
                  defaultValues={defaultValues}
                />
              </FormField>
            </Configurable>
          )
        }

        // TODO[BTPWA-702]: task 2
        return (
          <Configurable key={option.key}>
            <FormField label={option.label} fieldIdentifier={option.key}>
              <Select
                control={control}
                {...register(option.key, {
                  required: true,
                })}
                defaultValue={defaultOptionValue}
              >
                <>
                  <option value="">
                    <Trans>Select an option</Trans>
                  </option>

                  {option.values &&
                    option?.values.map((value) => (
                      <SelectOption
                        key={value.key}
                        value={value.key}
                        disabled={value.disabled}
                      >
                        {value.isPreorder && !value.disabled ? (
                          <Trans>{value.label} - preorder</Trans>
                        ) : (
                          value.label
                        )}
                      </SelectOption>
                    ))}
                </>
              </Select>
            </FormField>
          </Configurable>
        )
      })}
    </ConfigurableOptions>
  )
}

interface Props {
  /**
   * Product object
   */
  product: ProductFragment | ProductCardFragment
  /**
   * Product parent, in case product is SimpleProduct with a parent product
   */
  productParentData?: ProductParentFragment | null
  /**
   * Pass alert state on cart state change
   */
  handleCartStateChange: (alert: AlertProps | null) => void
  /**
   * Pass selected configurable values to parent component
   */
  onConfigurableValueSelect?(values: string[] | Record<string, any>): void
  /**
   * Configurable options, in case product is ConfigurableProduct
   */
  configurableOptions?: ProductConfigurableOptions[]
  /**
   * Should the add-to-cart button only show an icon?
   */
  isIconButton?: boolean
  /**
   * Should only the buttons be visible?
   */
  showButtonsOnly?: boolean
  /**
   * Should the add-to-cart button be hidden?
   */
  hideAddToCartButton?: boolean
  /**
   * Should the wishlist button be hidden?
   */
  hideWishlistButton?: boolean
  /**
   * Optional: change the add-to-cart button color type
   */
  addToCartButtonColorType?: ButtonColorType
  /**
   * Optional: use a custom text for the add to cart button
   */
  customAddToCartText?: ReactNode
  /**
   * Optional: If custom add-to-cart button text is used: does the button have both text and an icon?
   */
  addToCartButtonWithIcon?: boolean
  /**
   * Optional: children to place between add-to-cart components
   */
  children?: ReactNode
  /**
   * Product out of stock status
   */
  isOutOfStock: boolean
}

const ConfigurableAddToCartForm = ({
  product,
  productParentData,
  handleCartStateChange,
  onConfigurableValueSelect,
  configurableOptions,
  isIconButton = false,
  showButtonsOnly = false,
  hideAddToCartButton = false,
  hideWishlistButton = false,
  addToCartButtonColorType = 'green',
  customAddToCartText,
  addToCartButtonWithIcon = false,
  isOutOfStock,
  children,
  ...other
}: Props) => {
  const activeStoreView = useActiveStoreView()
  const { query } = useRouter()
  const addToCart = useAddToCart()
  const [cartStatus, setCartStatus] = useState<AddToCartStatus>('idle')
  const [showVariantMessage, setShowVariantMessage] = useState<boolean>(false)

  const isPreorder = getIsPreorder(product.preorder)

  const isMainProduct =
    product?.isMainProduct || product?.__typename === 'ConfigurableProduct'

  const preselectedOptionsProductParent =
    productParentData?.preselectOptions?.filter(stripMaybes) ?? []

  const defaultConfigurableOptionsProductParent =
    productParentData?.preselectOptions
      ? convertPreselectOptionsToDefaultValues(preselectedOptionsProductParent)
      : undefined

  const {
    register,
    watch,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    resetField,
    formState,
  } = useForm()

  const formValues = getValues()
  const hasFormErrors = Object.keys(formState.errors).length !== 0
  const selectedConfigurableOptions = Object.values(formValues).filter(
    (value) => value !== '',
  )

  useRedirectToSimpleProduct(
    product,
    productParentData,
    selectedConfigurableOptions,
  )

  const simpleProductConfigurableOptions = getRenderableConfigurableOptions(
    productParentData,
    formValues,
  )

  const configurableUrlParamsRef = useRef<boolean>()

  const dynamicConfigurableOptions =
    configurableOptions ?? simpleProductConfigurableOptions

  const colorVisualValue = watch('color_visual')
  const colorValue = watch('color')

  const handleAddToCart = useCallback(
    async (
      cartItem: Pick<CartItemInput, 'sku' | 'quantity' | 'selected_options'>,
    ) => {
      setCartStatus('adding')

      try {
        handleCartStateChange(null)

        const result = await addToCart(cartItem)

        pushAddToCart(
          [
            convertProductForTrackingEvent({
              product,
              quantity: 1,
              storeViewCode: activeStoreView.code,
            }),
          ],
          product.priceRange.minimumPrice.finalPrice.currency,
          product.priceRange.minimumPrice.finalPrice.value,
        )

        if (!result?.addProductsToCart?.userErrors.length) {
          setCartStatus('added')
          handleCartStateChange({
            type: 'success',
            message: (
              <Trans>
                {product?.name} has been added to your{' '}
                <NextLinkPrimary
                  href="/cart"
                  analyticsContext="add.to.cart.form"
                  analyticsName="cart"
                  isBasic
                >
                  cart
                </NextLinkPrimary>
                .
              </Trans>
            ),
          })

          return
        }

        setCartStatus('idle')

        handleCartStateChange({
          type: 'error',
          message: result.addProductsToCart.userErrors
            .map((item) => item?.message)
            .filter((item): item is string => Boolean(item)),
        })
      } catch (e) {
        if (e instanceof ApolloError) {
          handleCartStateChange({
            type: 'error',
            message: [e.message],
          })
        }

        setCartStatus('idle')
      }
    },
    [handleCartStateChange, addToCart, product],
  )

  const onSubmit = handleSubmit((data) => {
    if (!product?.sku) {
      return
    }

    if (product.isMainProduct) {
      setShowVariantMessage(true)

      return
    }

    const selectedOptions = configurableOptions
      ?.map((option) => data[option.key])
      .filter((item) => item && item !== '')

    // Add cart item to cart
    handleAddToCart({
      quantity: 1,
      sku: product?.sku,
      selected_options: (selectedOptions as string[]) ?? [],
    })
  })

  const { getItemBySku, wishlist } = useWishlist()

  /**
   * resets form fields in order to prevent, unavailable product configurations
   * @param attributeUid the uid of the attribute that should not be reset
   */
  const resetFormFields = (attributeUid: string) => {
    Object.entries(formValues).forEach((entry) => {
      if (entry[0] !== attributeUid) {
        setValue(entry[0], '')
      }
    })
  }

  /**
   * Reset form values when default configurable options are available
   */
  useEffect(() => {
    if (!dynamicConfigurableOptions) {
      return
    }

    if (
      defaultConfigurableOptionsProductParent &&
      !configurableUrlParamsRef.current
    ) {
      onConfigurableValueSelect?.(
        Object.values(defaultConfigurableOptionsProductParent),
      )

      configurableUrlParamsRef.current = true
    }
  }, [
    dynamicConfigurableOptions,
    query,
    reset,
    colorVisualValue,
    colorValue,
    defaultConfigurableOptionsProductParent,
    onConfigurableValueSelect,
  ])

  /**
   * Pass form values to onConfigurableValueSelect to get the available configurable options.
   * Should also update on 'query' update to pass values after default form values are processed
   */
  useEffect(() => {
    if (!onConfigurableValueSelect || !dynamicConfigurableOptions) {
      return
    }

    const res = watch((values, { name }) => {
      // sort configurable options by position:
      const sortedDynamicConfigurableOptions = sortArrayByProperty(
        dynamicConfigurableOptions ?? [],
        (option) =>
          option.position || option.position === 0 ? option.position : null,
      ).map((opt) => ({ ...opt, value: values[opt.key] }))

      // get the changed field
      const changedField = sortedDynamicConfigurableOptions.find(
        (opt) => opt.key === name,
      )

      let optionValues = sortedDynamicConfigurableOptions?.map(
        (opt) => opt.value,
      )

      // Slice values from 0 to index/position of the field that has a changed value
      if (changedField?.position) {
        optionValues = sortedDynamicConfigurableOptions
          .slice(0, changedField.position + 1)
          ?.map((opt) => opt.value)
      }

      onConfigurableValueSelect(optionValues?.filter(Boolean))
    })

    return () => res?.unsubscribe()
  }, [
    onConfigurableValueSelect,
    watch,
    query,
    dynamicConfigurableOptions,
    resetField,
    setValue,
  ])

  if (showButtonsOnly && dynamicConfigurableOptions.length !== 0) {
    return null
  }

  return (
    <form onSubmit={onSubmit} {...other}>
      {dynamicConfigurableOptions?.length !== 0 && !showButtonsOnly && (
        <ConfigurableForm
          configurableOptions={dynamicConfigurableOptions}
          control={control}
          formValues={formValues}
          register={register}
          resetFormFields={resetFormFields}
          setValue={setValue}
          defaultValues={defaultConfigurableOptionsProductParent}
        />
      )}

      {children && children}

      {(showVariantMessage || hasFormErrors) && (
        <VariantMessage>
          <Trans>Choose a version first</Trans>
        </VariantMessage>
      )}

      <ButtonWrapper>
        {!hideAddToCartButton && (
          <StyledButtonPrimary
            analyticsContext="add.to.cart.form"
            analyticsName="submit"
            type="submit"
            isIcon={isIconButton}
            disabled={
              cartStatus === 'adding' || (isOutOfStock && !isMainProduct)
            }
            colorType={addToCartButtonColorType}
            withIcon={Boolean(customAddToCartText) && addToCartButtonWithIcon}
          >
            {isOutOfStock && !isMainProduct ? (
              <Trans>Out of stock</Trans>
            ) : isIconButton ? (
              <StyledShoppingCartPlusIcon />
            ) : (
              customAddToCartText ??
              (isPreorder ? (
                <Trans>Pre-order</Trans>
              ) : (
                <Trans>Add to cart</Trans>
              ))
            )}
          </StyledButtonPrimary>
        )}

        {!hideWishlistButton && (
          <StyledWishlistIconButton
            analyticsContext="add.to.wishlist.form"
            analyticsName="submit"
            asButton
            wishlistId={wishlist?.id ?? ''}
            wishlistItem={getItemBySku(product?.sku)}
            product={product}
          />
        )}
      </ButtonWrapper>
    </form>
  )
}

export default ConfigurableAddToCartForm
