import { usePreviouslyViewedProducts } from '@emico-hooks/previously-viewed-products'
import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'

import { H2 } from '@emico/ui'

import useProductsBySkus from '../lib/useProductsBySkus'
import theme from '../theme'
import ProductSliderWithTracking from './ProductSliderWithTracking'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledH2 = styled(H2)`
  align-self: flex-start;
  margin: 0;
  font-size: ${theme.fontSizes['2xl']};
  text-transform: uppercase;
`

const StyledProductSliderWithTracking = styled(ProductSliderWithTracking)`
  margin-top: ${theme.spacing.xl};
`

interface Props {
  disableEcommerce: boolean
}

const PreviouslyViewedProducts = ({ disableEcommerce, ...other }: Props) => {
  const [data] = usePreviouslyViewedProducts()
  const { data: productData } = useProductsBySkus(data.length > 0 ? data : [''])

  const filteredProductData = productData.filter(
    (product) => product !== null,
  ) as ProductCardFragment[]

  const sectionTitle = t({ message: 'Recently viewed' })

  if (!filteredProductData || filteredProductData.length === 0) {
    return null
  }

  return (
    <Wrapper {...other}>
      <StyledH2>{sectionTitle}</StyledH2>

      <StyledProductSliderWithTracking
        disableEcommerce={disableEcommerce}
        products={filteredProductData}
        slidesToShowMobile={filteredProductData.length === 1 ? 1 : 1.25}
        slidesToShowTablet={2.5}
        slidesToShowDesktop={4}
        trackingInfo={{
          itemListId: 'recently_viewed',
          itemListName: sectionTitle,
        }}
      />
    </Wrapper>
  )
}

export default PreviouslyViewedProducts
