import React from 'react'

import { useRootCategory } from '@emico/category-graphql'

import { useCachedRootCategory } from '../lib/fetchCategoryList'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import UspBar from './UspBar'

const Header = ({ websiteData }: DefaultLayoutStaticData) => {
  const { data: cachedRootCategory } = useCachedRootCategory(2)
  const { data: fetchedRootCategory } = useRootCategory()
  const rootCategory = cachedRootCategory ?? fetchedRootCategory

  return (
    // No wrapping element should be used, to maintain sticky header styling
    <>
      <UspBar websiteData={websiteData} color="grayDark" />
      <HeaderDesktop websiteData={websiteData} rootCategory={rootCategory} />
      <HeaderMobile websiteData={websiteData} rootCategory={rootCategory} />
    </>
  )
}

export default Header
