import { FocusScope as ReactAreaFocusScope } from '@react-aria/focus'
import React, { ReactNode } from 'react'

/**
 * Use FocusScope to contain focus within the scope.
 * Change hasAutoFocus if the scope's first input shouldn't have auto focus.
 * Optionally use focusManager to move focus within the scope.
 * https://react-spectrum.adobe.com/react-aria/FocusScope.html
 */
interface Props {
  children: ReactNode
  hasAutoFocus?: boolean
}

const FocusScope = ({ children, hasAutoFocus = true }: Props) => (
  <ReactAreaFocusScope contain autoFocus={hasAutoFocus}>
    {children}
  </ReactAreaFocusScope>
)

export default FocusScope
