import { useCustomerWishlists } from '@emico-hooks/customer-wishlists'
import { useIsLoggedIn } from '@emico-hooks/login-token'
import { useCallback, useMemo } from 'react'

import { Wishlist, WishlistItemInterface } from '@emico/graphql-schema-types'

interface getWishlist {
  (wishlists: Wishlist[]): Wishlist
}

const getDefaultWishlist = (wishlists: Wishlist[]) => wishlists?.[0]

/**
 * Custom hook to get a wishlist from an array of wishlists
 *
 * @returns The picked wishlist and a function to get a WishlistItem by product sku.
 *
 * Project usage example:
 *
 * ```
 * const Component = () => {
 *  const { getItemBySku, wishlist } = useWishlist((wishlists)=> wishlists[0])
 *
 *  [...]
 * }
 * ```
 */

export const useWishlist = (callback: getWishlist = getDefaultWishlist) => {
  const isLoggedIn = useIsLoggedIn()
  const { wishlists } = useCustomerWishlists({ skip: !isLoggedIn })

  const wishlist = useMemo((): Wishlist | null => {
    if (!wishlists) {
      return null
    }

    return callback(wishlists as Wishlist[])
  }, [wishlists, callback])

  const getItemBySku = useCallback(
    (sku: string): WishlistItemInterface | null | undefined => {
      if (!wishlist) {
        return null
      }
      const items = wishlist?.itemsV2?.items ?? []
      const wishlistItem = items?.find((item) => item?.product?.sku === sku)

      return wishlistItem
    },
    [wishlist],
  )

  return {
    wishlist,
    getItemBySku,
  }
}
