import styled from '@emotion/styled'
import React, { MutableRefObject, useState } from 'react'
import { TransitionStatus } from 'react-transition-group/Transition'

import { Maybe } from '@emico/graphql-schema-types'

import { CraftMenuItemFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import MenuDesktopSubmenuItem from './MenuDesktopSubmenuItem'

const transformStates = {
  entering: '0%',
  entered: '0%',
  exited: '-100%',
  exiting: '-100%',
  unmounted: '-100%',
}

const Submenu = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'isSubActive',
      'transitionStatus',
      'height',
      'nestedTransitionStatus',
    ].includes(prop.toString()),
})<{
  isSubActive: boolean
  transitionStatus: TransitionStatus
  height?: number
  nestedTransitionStatus: TransitionStatus
}>`
  position: absolute;
  left: 0;
  top: 100%;
  transform: ${({ transitionStatus }) =>
    `translateY(${transformStates[transitionStatus]})`};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  min-height: ${({ height }) => `${height ?? 400}px`};
  width: 100%;
  z-index: ${({ isSubActive, nestedTransitionStatus }) =>
    isSubActive && nestedTransitionStatus !== 'exiting' ? -1 : -2};
`

interface Props {
  submenuItem: Maybe<CraftMenuItemFragment>
  isSubActive: boolean
  submenuItemRef: MutableRefObject<HTMLDivElement | null>
  transitionStatus: TransitionStatus
}

const MenuDesktopSubmenu = ({
  submenuItem,
  isSubActive,
  transitionStatus,
  submenuItemRef,
}: Props) => {
  const [nestedTransitionStatus, setNestedTransitionStatus] =
    useState(transitionStatus)

  return (
    <Submenu
      isSubActive={isSubActive}
      transitionStatus={transitionStatus}
      height={submenuItemRef.current?.clientHeight}
      nestedTransitionStatus={nestedTransitionStatus}
    >
      <MenuDesktopSubmenuItem
        submenuItem={submenuItem}
        isSubActive={isSubActive}
        submenuItemRef={submenuItemRef}
        setNestedTransitionStatus={setNestedTransitionStatus}
      />
    </Submenu>
  )
}

export default MenuDesktopSubmenu
