import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import { ProductFragment } from '@emico-hooks/product-fragment'
import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import { useAddToWishlist, useRemoveFromWishlist } from '@emico-hooks/wishlist'
import { pushAddToWishlist } from '@emico-utils/datalayer'
import { useCallback, useState } from 'react'

import { WishlistItemInterface } from '@emico/graphql-schema-types'

import convertProductForTrackingEvent from './convertProductForTrackingEvent'

/**
 * Custom hook to add or remove an item to a wishlist.
 *
 * @returns Add or remove wishlist-item function
 *
 * Project usage example:
 *
 * ```
 * const Component = () => {
 *  const { toggleWishlistItem, error } = useManageWishlistItem()
 *
 *  [...]
 * }
 * ```
 */

export const useManageWishlistItem = () => {
  const activeStoreView = useActiveStoreView()
  const [error, setError] = useState<Error | null>(null)

  const addToWishlist = useAddToWishlist()
  const removeFromWishlist = useRemoveFromWishlist()

  const toggleWishlistItem = useCallback(
    async (
      wishlistId: string,
      wishlistItem: WishlistItemInterface | undefined | null,
      product: ProductFragment | ProductCardFragment,
    ) => {
      const addOrRemoveItem = (func: () => void) => async () => {
        try {
          await func()
        } catch (error) {
          if (error instanceof Error) {
            setError(error)
          }
        }
      }

      if (wishlistId) {
        let addOrRemoveFunc

        if (!wishlistItem?.id) {
          addOrRemoveFunc = addOrRemoveItem(() => {
            addToWishlist({
              wishlistId,
              wishlistItems: [
                {
                  sku: product.sku,
                  quantity: 1,
                },
              ],
            })

            pushAddToWishlist(
              [
                convertProductForTrackingEvent({
                  product,
                  quantity: 1,
                  storeViewCode: activeStoreView.code,
                }),
              ],
              product.priceRange.minimumPrice.regularPrice.currency,
              product.priceRange.minimumPrice.finalPrice.value,
            )
          })
        } else {
          addOrRemoveFunc = addOrRemoveItem(() =>
            removeFromWishlist({
              wishlistId,
              wishlistItemsIds: [String(wishlistItem.id)],
            }),
          )
        }
        addOrRemoveFunc()
      }
    },
    [activeStoreView.code, addToWishlist, removeFromWishlist],
  )

  return {
    toggleWishlistItem,
    error,
  }
}
