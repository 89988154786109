import { useCart } from '@emico-hooks/cart'
import styled from '@emotion/styled'
import React from 'react'

import theme from '../theme'

const Quantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  height: 18px;
  width: 18px;
  font-size: ${theme.fontSizes.xs};
  border-radius: 100%;
  background-color: var(--cart-quantity-background, ${theme.colors.primary});
  color: var(--cart-quantity-color, ${theme.colors.textLight});
`

const CartQuantity = () => {
  const { data: cart } = useCart()
  const cartTotalQuantity = cart?.totalQuantity ?? 0

  if (cartTotalQuantity === 0) {
    return null
  }

  return <Quantity>{cartTotalQuantity}</Quantity>
}

export default CartQuantity
