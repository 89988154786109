import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import { pushViewItemList } from '@emico-utils/datalayer'
import React, { useEffect, useState } from 'react'

import getTrackingProducts from '../lib/getTrackingProducts'
import ProductSlider from './ProductSlider'

interface TrackingProps {
  /**
   * The ID of the list in which the item was presented to the user (e.g. `related_products`)
   */
  itemListId: string
  /**
   * The name of the list in which the item was presented to the user (e.g. `Related products`)
   */
  itemListName: string
}

interface Props {
  /**
   * Should e-commerce be disabled?
   */
  disableEcommerce: boolean
  /**
   * Products that should be shown in product slider
   */
  products: ProductCardFragment[]
  /**
   * Number of visible slides for mobile
   */
  slidesToShowMobile: number
  /**
   * Number of visible slides for tablet
   */
  slidesToShowTablet: number
  /**
   * Number of visible slides for desktop
   */
  slidesToShowDesktop: number
  /**
   * Should an add-to-cart button be visible in the product slider cards?
   */
  showAddToCartButton?: boolean
  /**
   * Info to pass to pass to the tracking event
   */
  trackingInfo: TrackingProps
}

const ProductSliderWithTracking = ({
  disableEcommerce,
  products,
  slidesToShowMobile,
  slidesToShowTablet,
  slidesToShowDesktop,
  showAddToCartButton = false,
  trackingInfo,
  ...other
}: Props) => {
  const activeStoreView = useActiveStoreView()

  const [isPushedViewItemList, setIsPushedViewItemList] =
    useState<boolean>(false)

  useEffect(() => {
    if (isPushedViewItemList || products?.length === 0) {
      return
    }

    setIsPushedViewItemList(true)

    const productList = getTrackingProducts(
      products,
      activeStoreView.code,
      true,
    ).slice(0, 18)

    pushViewItemList(
      productList,
      trackingInfo.itemListId,
      trackingInfo.itemListName,
    )
  }, [
    activeStoreView.code,
    isPushedViewItemList,
    products,
    trackingInfo.itemListId,
    trackingInfo.itemListName,
  ])

  return (
    <ProductSlider
      disableEcommerce={disableEcommerce}
      products={products}
      showAddToCartButton={showAddToCartButton}
      slidesToShowMobile={slidesToShowMobile}
      slidesToShowTablet={slidesToShowTablet}
      slidesToShowDesktop={slidesToShowDesktop}
      {...other}
    />
  )
}

export default ProductSliderWithTracking
