import styled from '@emotion/styled'
import { Plural } from '@lingui/macro'
import React from 'react'

import theme from '../theme'
import StarRating from './StarRating'

const isInteger = (number: number) => Number.isInteger(number)

const getStarValue = (value: number) =>
  isInteger(value) ? value : value.toFixed(1)

const Rating = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.span`
  margin-left: ${theme.spacing.xs};
`

interface Props {
  /**
   * Number of stars rated
   */
  value: number
  /**
   * Total number of stars
   */
  totalStars?: number
  /**
   * Total number of reviews
   * Pass when total should be shown next to stars (e.g. '44 reviews')
   */
  totalRecords?: number
  /**
   * Should star value be shown next to stars? (e.g. '4/5')
   */
  showStarValue?: boolean
  /**
   * Should total records be shown in a minimal way? (e.g. '(44)')
   * Should be passed together with 'totalRecords' prop
   */
  showTotalRecordsShort?: boolean
}

const StarRatingWithTotals = ({
  value,
  totalStars = 5,
  totalRecords,
  showStarValue = false,
  showTotalRecordsShort = false,
  ...other
}: Props) => {
  const showTotalRecords = Boolean(totalRecords) || totalRecords === 0

  return (
    <Rating {...other}>
      <StarRating value={value} totalStars={totalStars} />

      {showStarValue && !showTotalRecords && (
        <Label>
          {getStarValue(value)}/{totalStars}
        </Label>
      )}

      {showTotalRecords && !showTotalRecordsShort && (
        <Label>
          <Plural value={totalRecords ?? 0} one="# review" other="# reviews" />
        </Label>
      )}

      {showTotalRecords && showTotalRecordsShort && (
        <Label>({totalRecords})</Label>
      )}
    </Rating>
  )
}

export default StarRatingWithTotals
