import styled from '@emotion/styled'
import React, { MutableRefObject, useRef } from 'react'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { Maybe } from '@emico/graphql-schema-types'

import { CraftMenuItemFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import MenuDesktopSubmenuItemContent from './MenuDesktopSubmenuItemContent'

const SubmenuItem = styled.div`
  padding: 0 ${theme.containerPadding};
  position: relative;
`

interface Props {
  submenuItem: Maybe<CraftMenuItemFragment>
  isSubActive: boolean
  submenuItemRef: MutableRefObject<HTMLDivElement | null>
  setNestedTransitionStatus?: (
    nestedSubMenuTransitionStatus: TransitionStatus,
  ) => void
}

const MenuDesktopSubmenuItem = ({
  submenuItem,
  isSubActive,
  submenuItemRef,
  setNestedTransitionStatus,
}: Props) => {
  const nodeRef = useRef(null)

  return (
    <SubmenuItem>
      <Transition nodeRef={nodeRef} in={isSubActive} timeout={800}>
        {(transitionStatus: TransitionStatus) => (
          <MenuDesktopSubmenuItemContent
            submenuItem={submenuItem}
            submenuItemRef={submenuItemRef}
            transitionStatus={transitionStatus}
            setNestedTransitionStatus={setNestedTransitionStatus}
          />
        )}
      </Transition>
    </SubmenuItem>
  )
}

export default MenuDesktopSubmenuItem
