import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { TransitionStatus } from 'react-transition-group'

import { Maybe } from '@emico/graphql-schema-types'
import { maxWidth } from '@emico/styles'

import { CraftMenuItemFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import CraftGenericBlockLink from './CraftGenericBlockLink'
import CraftLink from './CraftLink'
import MenuMobileHeader from './MenuMobileHeader'

const transformStates = {
  entering: '0%',
  entered: '0%',
  exited: '100%',
  exiting: '100%',
  unmounted: '100%',
}

const borderStyling = css`
  margin-bottom: ${theme.spacing.lg};
  padding-bottom: ${theme.spacing.lg};
  border-bottom: ${theme.borders.default};
`

const SubMenu = styled('div', {
  shouldForwardProp: (prop) =>
    !['isMenuOpen', 'isSubOpen', 'isSubActive', 'transitionStatus'].includes(
      prop.toString(),
    ),
})<{
  isMenuOpen: boolean
  isSubOpen: boolean
  isSubActive: boolean
  transitionStatus: TransitionStatus
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: auto;
  width: 100%;
  background-color: ${theme.colors.background};
  z-index: ${theme.zIndices.modal};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  flex-shrink: 0;
  visibility: ${({ isSubOpen, isSubActive, transitionStatus }) =>
    (!isSubOpen && transitionStatus === 'exited') || !isSubActive
      ? 'hidden'
      : 'visible'};
  transform: translateX(
    ${({ transitionStatus, isMenuOpen, isSubOpen, isSubActive }) =>
      isSubOpen && isSubActive
        ? '0%'
        : isMenuOpen
        ? transformStates[transitionStatus]
        : '20%'}
  );
`

const SubmenuItem = styled.div`
  padding: ${theme.spacing.md};
`

const Column = styled.div`
  &:not(:last-of-type) {
    ${borderStyling};
  }
`

const LinkGroup = styled.div`
  margin-bottom: ${theme.spacing.lg};

  &:last-child {
    margin-bottom: 0;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  margin-bottom: ${theme.spacing.sm};

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledCraftLink = styled(CraftLink)`
  color: ${theme.colors.grayDark};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.grayDark};
    text-decoration: none;
  }
`

const UngroupedLinksListItem = styled.li`
  ${borderStyling};
  width: 100%;
`

const UngroupedCraftLink = styled(StyledCraftLink)`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  display: block;
`

const GroupedLinkList = styled(List)`
  flex-direction: column;
  align-items: flex-start;
`

const LinkGroupTitleLink = styled(StyledCraftLink)`
  display: flex;
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
`

const GroupedCraftLink = styled(StyledCraftLink)`
  padding-left: ${theme.spacing.md};
`

const StyledCraftGenericBlockLinkMobileAndTablet = styled(
  CraftGenericBlockLink,
)`
  @media ${maxWidth('md')} {
    display: none;
  }
`

interface Props {
  submenuItem: Maybe<CraftMenuItemFragment>
  close: () => void
  isMenuOpen: boolean
  isSubOpen: boolean
  isSubActive: boolean
  back: () => void
  transitionStatus: TransitionStatus
}

const MenuMobileSubmenu = ({
  submenuItem,
  close,
  isMenuOpen,
  isSubOpen,
  isSubActive,
  back,
  transitionStatus,
}: Props) => {
  const submenuImage = submenuItem?.image?.[0]
  const genericBlockLink = submenuItem?.genericBlockLink?.[0]

  return (
    <SubMenu
      transitionStatus={transitionStatus}
      isMenuOpen={isMenuOpen}
      isSubOpen={isSubOpen}
      isSubActive={isSubActive}
    >
      {submenuItem?.title && (
        <MenuMobileHeader
          title={submenuItem.title}
          image={submenuImage}
          close={close}
          back={back}
          isSubmenu
        />
      )}

      <SubmenuItem>
        {submenuItem?.menuLists?.map((menuList, index) => (
          <Column key={index}>
            {menuList?.links?.length !== 0 && (
              <List>
                {menuList?.links?.map((link, index) => {
                  if (!link?.linkItem?.url || !link.linkItem.customText) {
                    return null
                  }

                  return (
                    <UngroupedLinksListItem key={index}>
                      <UngroupedCraftLink
                        analyticsContext="header.desktop.mainLinks"
                        analyticsName={link.linkItem.url}
                        craftLink={link.linkItem}
                      >
                        {link.linkItem.customText}
                      </UngroupedCraftLink>
                    </UngroupedLinksListItem>
                  )
                })}
              </List>
            )}

            {menuList?.linkGroups?.map((linkGroup, index) => {
              const linkGroupTitleLink = linkGroup?.linkGroupTitleLink
              const linkGroupLinks = linkGroup?.linkGroupLinks

              return (
                <LinkGroup
                  key={`${linkGroup?.linkGroupTitleLink?.customText}-${index}`}
                >
                  {(linkGroupLinks?.length !== 0 ||
                    (linkGroupTitleLink?.url &&
                      linkGroupTitleLink.customText)) && (
                    <GroupedLinkList>
                      {linkGroupTitleLink?.url &&
                        linkGroupTitleLink.customText && (
                          <ListItem>
                            <LinkGroupTitleLink
                              craftLink={linkGroupTitleLink}
                              analyticsContext="header.desktop.mainLinks"
                              analyticsName={linkGroupTitleLink.customText}
                            >
                              {linkGroupTitleLink.customText}
                            </LinkGroupTitleLink>
                          </ListItem>
                        )}

                      {linkGroupLinks?.map((groupLink, index) =>
                        groupLink?.linkItem?.url &&
                        groupLink.linkItem.customText ? (
                          <ListItem key={index}>
                            <GroupedCraftLink craftLink={groupLink.linkItem}>
                              {groupLink.linkItem.customText}
                            </GroupedCraftLink>
                          </ListItem>
                        ) : null,
                      )}
                    </GroupedLinkList>
                  )}
                </LinkGroup>
              )
            })}
          </Column>
        ))}

        {genericBlockLink &&
          genericBlockLink.__typename === 'CraftGenericBlockLinkEntry' && (
            <StyledCraftGenericBlockLinkMobileAndTablet
              item={genericBlockLink}
            />
          )}
      </SubmenuItem>
    </SubMenu>
  )
}

export default MenuMobileSubmenu
