import { ProductParentFragment } from '@emico-hooks/product-fragment/src'
import { Unmaybe } from '@emico-utils/graphql-data-utils'

type PreselectOption = Unmaybe<
  Exclude<ProductParentFragment['preselectOptions'], null>
>

/**
 * Function to convert preselected configurable options to default values
 *
 * @param preselectOptions Array of preselected options objects
 * @returns Object in default-value-compatible format with dynamic key and value
 */

export const convertPreselectOptionsToDefaultValues = (
  preselectOptions?: PreselectOption[] | null,
):
  | {
      [k: string]: string
    }
  | undefined =>
  preselectOptions?.reduce(
    (acc, cur) => ({ ...acc, [cur.attributeCode]: cur.uid }),
    {},
  )
