import styled from '@emotion/styled'
import { useEffect, useMemo, useState } from 'react'

import CheckmarkIcon from '../icons/CheckmarkIcon'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import HtmlContent from './HtmlContent'

const INTERVAL_DELAY = 5000

interface WrapperProps {
  color: keyof typeof theme.colors | 'default'
}

const UspList = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'color',
})<WrapperProps>`
  margin: 0;
  padding: 0;
  width: 100%;
  height: ${theme.sizes.uspBarHeight};
  position: relative;
  background-color: ${(props) =>
    theme.colors[props.color === 'default' ? 'primary' : props.color] ??
    theme.colors.primary};
  z-index: ${theme.zIndices.banner};
`

const Usp = styled('li', {
  shouldForwardProp: (prop) => prop !== 'visible',
})<{ visible?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: ${theme.colors.textLight};
  font-size: ${theme.fontSizes.sm};
  font-weight: ${theme.fontWeights.medium};
  text-align: center;
  position: absolute;
  top: 50%;
  left: ${theme.containerPadding};
  right: ${theme.containerPadding};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.slow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  transform: ${({ visible }) =>
    visible ? 'scaleY(1) translateY(-50%)' : 'scaleY(0) translateY(-50%)'};

  p {
    margin: 0 ${theme.spacing.xs} 0 0;

    &:last-child {
      margin: 0;
    }
  }
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  margin-right: ${theme.spacing.sm};
  font-size: 16px;
  color: ${theme.colors.textLight};
`

interface Props extends DefaultLayoutStaticData {
  color: keyof typeof theme.colors
}

const UspBar = ({ websiteData, color }: Props) => {
  const [activeUspIndex, setActiveUspIndex] = useState(0)

  const uspsGlobalSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftUspsGlobalGlobalSet',
  )

  const usps = useMemo(
    () => uspsGlobalSet && 'usps' in uspsGlobalSet && uspsGlobalSet.usps,
    [uspsGlobalSet],
  )

  useEffect(() => {
    if (usps && usps.length <= 1) {
      return
    }

    const interval = setInterval(() => {
      setActiveUspIndex((activeUspIndex) => activeUspIndex + 1)
    }, INTERVAL_DELAY)

    return () => clearInterval(interval)
  }, [usps])

  if (!usps) {
    return null
  }

  return (
    <UspList color={color}>
      {usps.map((usp, index) => {
        if (!usp?.uspItem) {
          return null
        }

        return (
          <Usp
            key={usp.id}
            visible={index === activeUspIndex % (usps?.length ?? 0)}
          >
            {usp.showIcon && <StyledCheckmarkIcon />}

            <HtmlContent html={usp.uspItem} />
          </Usp>
        )
      })}
    </UspList>
  )
}

export default UspBar
