import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import {
  ProductFragment,
  ProductParentFragment,
} from '@emico-hooks/product-fragment'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { useRouter } from 'next/router'

import { ProductStockStatus } from '@emico/graphql-schema-types/src'

import { getProductVariantsBasedOnSelectedOptions } from './getProductVariantsBasedOnSelectedOptions'

/**
 *  Function to redirect to a new SimpleProduct product page,
 *  in case a new configurable options selection results in another SimpleProduct
 *
 * @param product Current product
 * @param productParentData Current product's parent data
 * @param selectedConfigurableOptions Selected configurable options
 */

export const useRedirectToSimpleProduct = (
  product: ProductFragment | ProductCardFragment,
  productParentData: ProductParentFragment | undefined | null,
  selectedConfigurableOptions: string[],
) => {
  const { push } = useRouter()

  const parentProduct =
    productParentData?.product &&
    'configurableOptions' in productParentData.product
      ? productParentData.product
      : undefined

  const parentProductVariantsInStock =
    parentProduct?.variants
      ?.filter(
        (variant) =>
          variant?.product?.stockStatus === ProductStockStatus.IN_STOCK,
      )
      .filter(stripMaybes) ?? []

  const availabeParentProductVariantsBasedOnSelectedOptions =
    getProductVariantsBasedOnSelectedOptions(
      parentProductVariantsInStock,
      selectedConfigurableOptions,
    )

  const newConfiguredSimpleProduct =
    availabeParentProductVariantsBasedOnSelectedOptions.length === 1
      ? availabeParentProductVariantsBasedOnSelectedOptions[0]
      : undefined

  if (
    newConfiguredSimpleProduct?.product?.uid &&
    product?.uid !== newConfiguredSimpleProduct.product.uid
  ) {
    push(newConfiguredSimpleProduct?.product?.urlKey)
  }
}
