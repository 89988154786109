import { ProductStockStatus } from '@emico/graphql-schema-types/src'

interface ConfigurableAttributeOption {
  uid: string
  code: string | null
  label: string | null
  valueIndex: number | null
}

interface SimpleProduct {
  uid: string
  urlKey: string
  onlyXLeftInStock: number | null
  stockStatus: ProductStockStatus | null

  image: {
    url: string | null
  } | null
}

interface ConfigurableVariant {
  attributes: Array<ConfigurableAttributeOption | null> | null
  product: SimpleProduct | null
}

/**
 * Function to filter an array of configurable product variants, based on selected configurable options.
 * Variants that contain all selected configurable options will be returned.
 * e.g. When two variants are passed, one with color 'red' the other with color 'green' and color 'green' is passed as selected option,
 * only the variant with color 'green' will be returned.
 *
 * @param variants Available variants of a configurable product
 * @param selectedConfigurableOptions  Selected configurable options to determine which variants will be returned
 * @returns Array of variants
 */

export function getProductVariantsBasedOnSelectedOptions(
  variants: ConfigurableVariant[],
  selectedConfigurableOptions: string[],
) {
  return variants
    ?.map((variant) => {
      const isEverySelectedOptionIncludedInVariant =
        selectedConfigurableOptions?.every((option) =>
          variant?.attributes?.find((attribute) => attribute?.uid === option),
        )

      return !selectedConfigurableOptions ||
        selectedConfigurableOptions?.length === 0 ||
        isEverySelectedOptionIncludedInVariant
        ? variant
        : undefined
    })
    .filter((variant) => variant)
}
