import styled from '@emotion/styled'
import React, { InputHTMLAttributes, ReactNode } from 'react'

const Option = styled.option`
  &:disabled {
    color: #999999;
  }
`

/**
 * Select option component with disabled styling fallback
 */

interface Props extends InputHTMLAttributes<HTMLOptionElement> {
  children: ReactNode
}

const SelectOption = ({ children, ...other }: Props) => (
  <Option {...other}>{children}</Option>
)

export default SelectOption
