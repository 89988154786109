import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { Maybe } from '@emico/graphql-schema-types'
import ModalBackdrop from '@emico/modal-backdrop'
import { Container } from '@emico/ui'
import { stripMaybes } from '@emico/utils'

import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import typography from '../theme/typography'
import CraftLink from './CraftLink'
import MenuDesktopItemWithChildren from './MenuDesktopItemWithChildren'
import NextLink from './NextLink'

const Nav = styled.nav`
  position: relative;
  z-index: ${theme.zIndices.navbar - 1};
`

const LinkWrapper = styled.div`
  position: relative;
  background-color: ${theme.colors.background};
  z-index: ${theme.zIndices.navbar};
`

const List = styled('ul', {
  shouldForwardProp: (prop) =>
    !['show', 'activeSubmenuHeight'].includes(prop.toString()),
})<{ show: boolean; activeSubmenuHeight?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: ${theme.sizes.headerCollapsibleHeight};
  background-color: ${theme.colors.background};
  gap: ${theme.spacing['2xl']};

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${theme.colors.border};
    width: 100%;
    height: 1px;
  }

  &::after {
    content: '';
    display: inline-block;
    background-color: ${theme.colors.background};
    position: absolute;
    bottom: 0;
    left: 0;
    transform: ${({ show }) => `translateY(${show ? '100%' : '0%'})`};
    transition-property: ${theme.transition.properties.common};
    transition-duration: ${theme.transition.durations.normal};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
    height: ${({ activeSubmenuHeight }) => `${activeSubmenuHeight ?? 400}px`};
    width: 100%;
    z-index: -3;
  }
`

export const LinkLabel = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: ${theme.borders.md} transparent;
  transition-property: ${theme.transition.properties.colors};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
`

const StyledCraftLink = styled(CraftLink)`
  color: ${theme.colors.grayDark};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.grayDark};
    text-decoration: none;
  }
`

const mainLinkStyling = css`
  display: flex;
  font-size: ${theme.fontSizes.md};
  font-weight: ${typography.fontWeights.medium};
  text-transform: uppercase;
`

export const StyledNextLink = styled(NextLink, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop.toString()),
})<{ isActive?: boolean }>`
  color: ${theme.colors.grayDark};
  text-decoration: none;
  ${mainLinkStyling};

  &:hover {
    color: ${theme.colors.grayDark};
    text-decoration: none;
  }

  ${LinkLabel} {
    border-color: ${({ isActive }) => isActive && theme.colors.grayDark};
  }
`

export const MainLinkCraftLink = styled(StyledCraftLink, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop.toString()),
})<{ isActive?: boolean }>`
  ${mainLinkStyling};

  ${LinkLabel} {
    border-color: ${({ isActive }) => isActive && theme.colors.grayDark};
  }
`

export const ListItem = styled.li`
  display: flex;
  height: 100%;
`

const StyledModalBackdrop = styled(ModalBackdrop)`
  height: 100vh;
  z-index: ${theme.zIndices.navbar - 1};
`

interface Props extends DefaultLayoutStaticData {
  rootCategory: CategoryFragmentWithChildren
  handleActiveCategory: (hasActiveCategory: boolean) => void
  show: boolean
}

const MenuDesktopMainLinks = ({
  websiteData,
  rootCategory,
  handleActiveCategory,
  show,
  ...other
}: Props) => {
  const router = useRouter()
  const rootCategoryChildren =
    rootCategory?.children
      ?.filter(stripMaybes)
      .filter((category) => category?.includeInMenu === 1)
      .sort((a, b) => (a?.position || 0) - (b?.position || 0)) ?? []

  const [isSubOpen, setIsSubOpen] = useState<boolean>(false)
  const [activeSubmenuHeight, setActiveSubmenuHeight] = useState<number>()
  const [activeCategory, setActiveCategory] =
    useState<CategoryFragmentWithChildren | null>(null)

  const menuGlobalSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftMenuGlobalSet',
  )

  const menuItemIds =
    menuGlobalSet?.__typename === 'CraftMenuGlobalSet'
      ? menuGlobalSet.menuItems
          .filter((item) => item?.menuItem?.[0])
          .map((item) => item?.categoryId) ?? []
      : []

  const handleItemWithChildren = (category: CategoryFragmentWithChildren) => {
    setIsSubOpen(true)
    setActiveCategory(category)
  }

  const handleItemWithoutChildren = (
    category: CategoryFragmentWithChildren,
  ) => {
    setIsSubOpen(false)
    setActiveCategory(category)
  }

  const handleCategoryLeave = () => {
    setIsSubOpen(false)
    setActiveCategory(null)
  }

  useEffect(() => {
    setActiveSubmenuHeight(activeSubmenuHeight)
  }, [activeSubmenuHeight])

  useEffect(() => {
    handleActiveCategory(Boolean(activeCategory))
  }, [activeCategory, handleActiveCategory])

  useEffect(() => {
    setIsSubOpen(false)
    // A navigation event occurs when the router asPath changes, a navigation event
    // should close the submenu (e.g. menu item click).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  return (
    <>
      <StyledModalBackdrop show={show && isSubOpen} />

      <Nav {...other}>
        <div onMouseLeave={handleCategoryLeave}>
          <LinkWrapper>
            <Container>
              <List
                show={show && isSubOpen}
                activeSubmenuHeight={activeSubmenuHeight}
              >
                {rootCategoryChildren.map(
                  (category: Maybe<CategoryFragmentWithChildren>) => {
                    if (!category?.name) {
                      return null
                    }

                    const hasChildren = menuItemIds.includes(category.id)
                    const menuItem =
                      menuGlobalSet?.__typename === 'CraftMenuGlobalSet'
                        ? menuGlobalSet.menuItems?.find(
                            (item) => item?.categoryId === category.id,
                          )
                        : undefined

                    const submenuItem = menuItem?.menuItem?.[0]
                    const isSubActive = category.id === activeCategory?.id

                    return hasChildren ? (
                      <MenuDesktopItemWithChildren
                        key={category.id}
                        category={category}
                        activeCategory={activeCategory}
                        handleItemWithChildren={handleItemWithChildren}
                        submenuItem={
                          submenuItem?.__typename === 'CraftMenuItemEntry'
                            ? submenuItem
                            : null
                        }
                        deviatingUrl={menuItem?.deviatingUrl ?? undefined}
                        show={show && isSubOpen}
                        isSubActive={isSubActive}
                        setActiveSubmenuHeight={setActiveSubmenuHeight}
                      />
                    ) : (
                      <ListItem
                        key={category.id}
                        onMouseEnter={() => handleItemWithoutChildren(category)}
                      >
                        <StyledNextLink
                          analyticsContext="header.desktop.mainLinks"
                          analyticsName={category.name}
                          isActive={activeCategory === category}
                          href={`/${
                            category.canonicalUrl ??
                            category.urlPath ??
                            category.urlKey
                          }`}
                        >
                          <LinkLabel>{category.name}</LinkLabel>
                        </StyledNextLink>
                      </ListItem>
                    )
                  },
                )}

                {menuGlobalSet?.__typename === 'CraftMenuGlobalSet' &&
                  menuGlobalSet.menuLinks?.length !== 0 &&
                  menuGlobalSet.menuLinks?.map((link, index) => {
                    if (!link?.linkItem?.url || !link.linkItem.customText) {
                      return null
                    }

                    return (
                      <ListItem key={index} onMouseEnter={handleCategoryLeave}>
                        <MainLinkCraftLink
                          analyticsContext="header.desktop.mainLinks"
                          analyticsName={link?.linkItem?.url}
                          craftLink={link.linkItem}
                        >
                          <LinkLabel>{link.linkItem.customText}</LinkLabel>
                        </MainLinkCraftLink>
                      </ListItem>
                    )
                  })}
              </List>
            </Container>
          </LinkWrapper>
        </div>
      </Nav>
    </>
  )
}

export default MenuDesktopMainLinks
